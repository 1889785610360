import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@knack/asterisk-react';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type ViewSubmitRule } from '@/types/schema/LiveAppView';
import { isEveryCriteriaMet } from '@/utils/criteriaRules';
import { useViewContext } from '@/components/views/ViewContext';
import { usePageSegmentsHelpers } from './helpers/usePageSegmentsHelpers';

type HandleSubmitRuleActionParams = {
  applicableRule: ViewSubmitRule;
  shouldShowToast?: boolean;
};

export function useViewRules() {
  const navigate = useNavigate();
  const { presentToast } = useToast();

  const { sourceTable } = useViewContext();
  const { getRedirectToChildPagePathFromSlug, getRedirectToPagePathFromSlug, getPreviousPagePath } =
    usePageSegmentsHelpers();

  const [applicableSubmitRuleValues, setApplicableSubmitRuleValues] = useState({
    confirmationMessage: '',
    shouldShowReloadButton: false,
    shouldReloadAutomatically: false
  });

  const sourceTableFields = useMemo(() => sourceTable?.fields ?? [], [sourceTable]);

  const checkCriteriaRules = useCallback(
    (data: { [key: string]: unknown }, criteria: KnackCriteria[]) =>
      isEveryCriteriaMet(data, sourceTableFields, criteria),
    [sourceTableFields]
  );

  const handleSubmitRuleAction = ({
    applicableRule,
    shouldShowToast = false
  }: HandleSubmitRuleActionParams) => {
    const {
      action,
      url,
      existing_page: existingPage,
      scene,
      message,
      reload_show: shouldShowReloadButton,
      reload_auto: shouldReloadAutomatically
    } = applicableRule;

    setApplicableSubmitRuleValues((prev) => ({
      ...prev,
      shouldShowReloadButton: shouldShowReloadButton || false,
      shouldReloadAutomatically: shouldReloadAutomatically || false
    }));

    let formattedUrl = url || '/';

    if (action === 'url' && url) {
      if (!url.includes('http')) {
        formattedUrl = `//${url}`;
      } else {
        formattedUrl = url;
      }
      window.location.href = formattedUrl;
    }

    if (action === 'existing_page' && existingPage) {
      void navigate(getRedirectToPagePathFromSlug(existingPage, sourceTable?.key));
    }

    if (action === 'child_page' && scene) {
      void navigate(getRedirectToChildPagePathFromSlug(scene));
    }

    if (action === 'parent_page') {
      void navigate(getPreviousPagePath());
    }

    if (action === 'message') {
      if (shouldShowToast) {
        presentToast({
          content: message
        });
        return;
      }

      setApplicableSubmitRuleValues((prev) => ({
        ...prev,
        confirmationMessage: message
      }));
    }
  };

  const handleFormSubmitRules = (
    matchingSubmitRuleKey: string | boolean,
    rules: ViewSubmitRule[]
  ) => {
    const applicableRule = rules.find((rule) => rule.key === matchingSubmitRuleKey);

    if (!applicableRule) return;

    handleSubmitRuleAction({ applicableRule });
  };

  return {
    applicableSubmitRuleValues,
    setApplicableSubmitRuleValues,
    handleFormSubmitRules,
    handleSubmitRuleAction,
    checkCriteriaRules
  };
}
