import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Calendar, EventModel, Widget } from '@bryntum/calendar';
import { BryntumCalendar, type BryntumCalendarProps } from '@bryntum/calendar-react';
import { useToast } from '@knack/asterisk-react';

import { getCalendarDefaultProps } from './calendarConfig';

import './calendar.css';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { useDeleteRecordMutation } from '@/hooks/api/mutations/useDeleteRecordMutation';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { useViewMultipleRecordsQuery } from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { DetailsOuterColumns } from '@/components/views/details/DetailsOuterColumns';
import { type RepeatOptions } from '@/components/views/form/inputs/date-time/types';
import { getDefaultDatePayload } from '@/components/views/form/inputs/date-time/utils';
import { getFormViewInputs } from '@/components/views/form/utils';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { CalendarForm } from './CalendarForm';
import { CalendarUtils } from './CalendarUtils';
import { getBryntumEvents, getFilters, mappedMode, mappedWeekStartDay, someDeep } from './helper';
import { type CalendarRef } from './type';

const getEventId = (recordId?: string) =>
  recordId?.startsWith('_generated:') ? recordId.split(':')[1] : recordId;

export function CalendarViewRender() {
  const { theme } = useThemingContext();
  const { view, sourceTable } = useViewContext<CalendarView>();

  const { canFieldStoreDateValues } = useFieldHelpers();
  const calendarRef = useRef<BryntumCalendar | null>(null);
  const previousMode = useRef('week');
  const formRef = useRef<CalendarRef>(null);
  const [calendarInstance, setCalendarInstance] = useState<Calendar>();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
  });
  const { mutate: deleteRecord } = useDeleteRecordMutation();
  const { mutate: updateRecord } = useUpdateRecordMutation({
    viewKey: view.key
  });
  const { presentToast } = useToast();
  const canDelete = someDeep(view.details.columns, 'type', 'delete');
  const [t] = useTranslation();

  const tableKey = view.source.object;

  const { data, isLoading } = useViewMultipleRecordsQuery({
    view,
    objectKey: tableKey,
    options: {
      filters: getFilters({ ...dateRange, key: view.events.event_field.key })
    }
  });

  const eventKey = view.events.event_field.key;
  const labelKey = view.events.label_field.key;

  const events = getBryntumEvents({
    tableData: data?.records || [],
    eventField: eventKey,
    labelField: labelKey,
    sourceTable,
    event_color_default: view.events.event_color_default,
    event_colors: view.events.event_colors
  });

  const transformedViewData = useMemo(
    () => ({
      mode: view.events.display_type === 'calendar' ? mappedMode[view.events.view] : 'agenda',
      weekStartDay: mappedWeekStartDay[view.events.week_start],
      hideNonWorkingDays: !!view.events.exclude_weekends,
      dayStartTime: view.events.time_min || '0:00',
      dayEndTime: view.events.time_max || '24:00',
      disableEventEditFeature: !view.events.allow_edit,
      disableEventTooltipFeature: !view.events.show_details,
      disableEventCreate: !view.events.allow_add,
      canDelete
    }),
    [view, canDelete]
  );

  const handleToggle = () => {
    if (calendarRef.current) {
      const currentMode = calendarRef.current.instance.mode;
      if (currentMode !== 'agenda') {
        previousMode.current = currentMode;
        calendarRef.current.instance.mode = 'agenda';
      } else {
        calendarRef.current.instance.mode = previousMode.current;
      }
    }
  };

  const handleDelete = async (recordId: string) =>
    new Promise((resolve) => {
      deleteRecord(
        {
          viewKey: view.key,
          recordId
        },
        {
          onSuccess: () => {
            presentToast({
              title: t('components.views.table.record_delete_success')
            });
            resolve(false);
          },
          onError: () => {
            presentToast({
              title: t('components.views.table.record_delete_error'),
              intent: 'destructive'
            });
            resolve(false);
          }
        }
      );
    });

  const handleEdit = async (
    newStartDate: Date,
    newEndDate: Date,
    eventRecord: EventModel & { data?: { realEventId: string } & Record<KnackFieldKey, KnackField> }
  ) =>
    new Promise((resolve) => {
      const eventField = sourceTable.fields.find((field) => field.key === eventKey);
      if (eventField && eventRecord.data?.realEventId && canFieldStoreDateValues(eventField)) {
        const eventFormat = eventField.format;

        let formatedDate: ReturnType<typeof getDefaultDatePayload> & { repeat?: RepeatOptions } =
          getDefaultDatePayload(eventFormat, {
            startDate: newStartDate,
            endDate: newEndDate,
            eventKey
          });

        if ('repeat' in eventRecord.data[eventKey] && eventRecord.data[eventKey].repeat) {
          formatedDate = {
            ...formatedDate,
            repeat: eventRecord.data[eventKey].repeat as RepeatOptions
          };
        }

        updateRecord(
          {
            data: { [eventKey]: formatedDate },
            recordId: eventRecord.data.realEventId
          },
          {
            onSuccess: ({ record: updatedRecord }) => {
              resolve(updatedRecord);
            },
            onError: () => resolve(false)
          }
        );
      } else {
        resolve(false);
      }
    });

  const calendarProps = useMemo(
    () =>
      getCalendarDefaultProps({
        today: new Date(),
        onToggle: handleToggle,
        ...transformedViewData
      }),
    [transformedViewData]
  );

  useEffect(() => {
    if (calendarRef.current?.instance) {
      setCalendarInstance(calendarRef.current?.instance);
    }
  }, [calendarRef]);

  useEffect(() => {
    if (calendarRef.current?.instance) {
      calendarRef.current.instance.modes.agenda.setConfig({
        emptyText: isLoading
          ? t('components.views.calendar.loading_events')
          : t('components.views.calendar.no_events'),
        range: mappedMode[view.events.view]
      });
    }
  }, [calendarRef, isLoading, t, view.events.view]);

  const tbarConfig = useMemo<BryntumCalendarProps['tbar']>(
    () => ({
      items: {
        modeSelector: { weight: 100 },
        includeWeekendsButton: false,
        todayButton: { weight: 150 },
        prevButton: { weight: 200 },
        viewDescription: { weight: 300 },
        nextButton: { weight: 400 },
        spacer: { weight: 450 },
        spacer2: {
          text: '',
          flex: 1,
          cls: 'border-none bg-card',
          weight: 180
        },
        filter: {
          icon: 'b-fa b-fa-list',
          weight: 500,
          tooltip: t('components.views.calendar.toggle_view'),
          onAction: handleToggle
        }
      }
    }),
    [t]
  );

  const recurrenceConfirmationConfig = useMemo<BryntumCalendarProps['recurrenceConfirmationPopup']>(
    () => ({
      bbar: {
        items: {
          changeSingleButton: null
        }
      },
      listeners: {
        async beforeHide(event: { source: Widget }) {
          const { confirmed } = event.source as unknown as { confirmed: boolean };
          if (confirmed) {
            return !!(await formRef.current?.submitForm());
          }
          return true;
        }
      }
    }),
    []
  );

  const tooltipFeatureConfig = useMemo(
    () => ({
      align: 'l-r',
      disabled: transformedViewData.disableEventTooltipFeature,
      renderer: ({ eventRecord }: { eventRecord: EventModel }) => {
        const currentRecord = (eventRecord as ViewRecord).record;

        return (
          <div className="w-full pb-2">
            {currentRecord && <DetailsOuterColumns record={currentRecord} />}
          </div>
        );
      },
      titleRenderer(record: EventModel) {
        return {
          html: record.name
        };
      },
      ...(!canDelete && {
        tooltip: {
          tools: {
            delete: null
          }
        }
      })
    }),
    [canDelete, transformedViewData.disableEventTooltipFeature]
  );

  return (
    <div data-kn="calendar-view" data-rounded={theme.appearance.corners === 'rounded'}>
      <CalendarUtils
        calendarInstance={calendarInstance}
        buttons={{
          shouldEnableEvent: view.events.allow_add
        }}
      />
      <BryntumCalendar
        {...calendarProps}
        tbar={tbarConfig}
        ref={calendarRef}
        events={events}
        onBeforeEventEditShow={({ eventRecord, editor }) => {
          let eventFormValue;

          // get form values if it is an existing event
          if (!editor.record.hasGeneratedId || eventRecord.recurrenceRule) {
            const record = eventRecord as ViewRecord;
            const baseId = getEventId(record.id);
            const formViewInputs = getFormViewInputs(view.form.groups);
            const formValueMap = new Map();
            formViewInputs.forEach((inputs) => {
              if (inputs.type !== 'divider' && inputs.type !== 'section_break') {
                const inputKey = inputs.field.key;
                if (inputKey) {
                  formValueMap.set(inputKey, record?.[inputKey]);
                }
              }
            });
            formValueMap.set('id', baseId);

            eventFormValue = Object.fromEntries(formValueMap);
          }

          // render form
          editor.setConfig({
            items: {
              mainForm: {
                type: 'widget',
                name: 'mainForm',
                weight: 1,
                html: (
                  <CalendarForm
                    view={view}
                    sourceTable={sourceTable}
                    recordValues={eventFormValue}
                    startDate={new Date(eventRecord.startDate)}
                    endDate={new Date(eventRecord.endDate)}
                    eventKey={eventKey}
                    ref={formRef}
                  />
                )
              }
            }
          });
        }}
        onBeforeEventSave={async ({ eventRecord }) => {
          if (!eventRecord?.isRecurring) {
            return !!(await formRef.current?.submitForm());
          }
          return true;
        }}
        onAfterEventEdit={({ action }) => {
          if (action === 'cancel') {
            formRef.current?.cancelForm();
          }
        }}
        onBeforeDragResizeEnd={async ({ newStartDate, newEndDate, eventRecord }) =>
          !!(await handleEdit(newStartDate, newEndDate, eventRecord))
        }
        onBeforeDragMoveEnd={async ({ newStartDate, newEndDate, eventRecord }) =>
          !!(await handleEdit(newStartDate, newEndDate, eventRecord))
        }
        onBeforeEventDelete={async ({ eventRecords }) =>
          !!(await handleDelete(String(eventRecords[0].id)))
        }
        listeners={{
          dateRangeChange({ new: { startDate, endDate } }) {
            setDateRange({ startDate, endDate });
          }
        }}
        recurrenceConfirmationPopup={recurrenceConfirmationConfig}
        eventTooltipFeature={tooltipFeatureConfig}
      />
    </div>
  );
}
