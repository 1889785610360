import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type TableView } from '@/types/schema/views/TableView';
import { cn } from '@/utils/tailwind';
import { useViewContext } from '@/components/views/ViewContext';
import { LargeViewFooter } from './LargeViewFooter';
import { SmallViewFooter } from './SmallViewFooter';

interface ViewFooterSectionProps {
  totalPageNumber: number;
  className?: string;
  isSmallFooter?: boolean;
  totalRecords?: number;
}

export function ViewFooterSection({
  totalPageNumber,
  totalRecords,
  className,
  isSmallFooter = false
}: ViewFooterSectionProps) {
  const { view } = useViewContext<ListView | TableView | MapView>();

  const { allow_limit: shouldAllowChangeRowsPerPage } = view;

  const shouldRenderPagination = totalPageNumber > 1;
  const shouldRenderFooter = shouldAllowChangeRowsPerPage || shouldRenderPagination;

  if (!shouldRenderFooter) {
    return null;
  }

  if (!shouldAllowChangeRowsPerPage && !shouldRenderPagination) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex w-full items-center justify-between gap-3 border-t border-default p-2 md:flex-row',
        className,
        {
          'justify-end': !shouldAllowChangeRowsPerPage && !totalRecords
        }
      )}
    >
      {isSmallFooter ? (
        <SmallViewFooter
          shouldAllowChangeRowsPerPage={shouldAllowChangeRowsPerPage}
          shouldRenderPagination={shouldRenderPagination}
          totalPageNumber={totalPageNumber}
          totalRecords={totalRecords}
        />
      ) : (
        <LargeViewFooter
          shouldAllowChangeRowsPerPage={shouldAllowChangeRowsPerPage}
          shouldRenderPagination={shouldRenderPagination}
          totalPageNumber={totalPageNumber}
          totalRecords={totalRecords}
        />
      )}
    </div>
  );
}
