import { RichTextRenderer } from '@knack/asterisk-react';
import DOMPurify from 'dompurify';

import { type RichTextView } from '@/types/schema/views/RichTextView';
import { useViewContext } from '@/components/views/ViewContext';

export function RichTextViewRender() {
  const { view } = useViewContext<RichTextView>();
  const sanitizedValue = DOMPurify.sanitize(view.content);

  return (
    <RichTextRenderer
      dangerouslySetInnerHTML={{
        __html: sanitizedValue
      }}
    />
  );
}
