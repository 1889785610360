import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { ThemeProviderContext, type AppTheme } from '@knack/asterisk-react';

export function AppBackground({ theme }: { theme: AppTheme }) {
  const { isDarkMode } = useContext(ThemeProviderContext);
  const bgImage = isDarkMode ? theme.background.dark : theme.background.light;

  return (
    <Helmet>
      <style>
        {`
        body {
          font-family: '${typeof theme.font === 'string' ? theme.font : theme.font.name}', sans-serif;
        }
        `}
      </style>
      {bgImage && (
        <style>
          {`
          html {
            min-height: 100%;
          }
          body {
            background-image: ${bgImage || 'none'};
            background-repeat: no-repeat;
          }`}
        </style>
      )}
    </Helmet>
  );
}
