import { memo } from 'react';
import { Card } from '@knack/asterisk-react';

import { type KnackViewType, type LiveAppView } from '@/types/schema/LiveAppView';
import { FEATURE_FLAGS, isFlagEnabled } from '@/utils/flagsmith';
import { cn } from '@/utils/tailwind';
import { MapViewRender } from '@/components/views/map/MapViewRender';
import { LoginViewRender } from './auth/login/LoginViewRender';
import { CalendarViewRender } from './calendar/CalendarViewRender';
import { DetailsViewRender } from './details/DetailsViewRender';
import { FallBackView } from './FallBackView';
import { FormViewRender } from './form/FormViewRender';
import { ListViewRender } from './list/ListViewRender';
import { MenuViewRender } from './menu/MenuViewRender';
import { ReportViewRender } from './report/ReportViewRender';
import { RichTextViewRender } from './rich-text/RichTextViewRender';
import { TableViewRender } from './table/TableViewRender';
import { ViewContextProvider } from './ViewContext';

function StaticView({ view }: { view: LiveAppView }) {
  switch (view.type) {
    case 'menu':
      return <MenuViewRender />;
    case 'rich_text':
      return <RichTextViewRender />;
    case 'login':
      return <LoginViewRender />;
    default:
      return <FallBackView />;
  }
}

function RecordView({ view }: { view: LiveAppView }) {
  const isCalendarEnabled = isFlagEnabled(FEATURE_FLAGS.fe_calendar);
  switch (view.type) {
    case 'form':
    case 'registration':
      return <FormViewRender />;
    case 'table':
      return <TableViewRender />;
    case 'list':
      return <ListViewRender />;
    case 'details':
      return <DetailsViewRender />;
    case 'report':
      return <ReportViewRender />;
    case 'map':
      return <MapViewRender />;
    case 'calendar':
      return isCalendarEnabled ? <CalendarViewRender /> : <FallBackView />;
    default:
      return <FallBackView />;
  }
}

export function ViewRender({ view }: { view: LiveAppView }) {
  const staticViewTypes: KnackViewType[] = ['rich_text', 'menu', 'login'];
  const recordViewTypes: KnackViewType[] = [
    'table',
    'list',
    'search',
    'details',
    'form',
    'calendar',
    'map',
    'report',
    'checkout',
    'registration'
  ];

  let ViewToRender = null;

  if (recordViewTypes.includes(view.type)) {
    ViewToRender = <RecordView view={view} />;
  }

  if (staticViewTypes.includes(view.type)) {
    ViewToRender = <StaticView view={view} />;
  }

  if (ViewToRender) {
    // Menu views don't need to be wrapped in a card
    if (view.type === 'menu') {
      return ViewToRender;
    }

    return (
      <Card className="w-full p-4 sm:p-4" data-kn={view.key}>
        {ViewToRender}
      </Card>
    );
  }

  return null;
}

function getViewTypeClass(view: LiveAppView) {
  switch (view.type) {
    case 'rich_text':
      return 'rich-text';
    case 'form':
    case 'registration':
    case 'table':
    case 'list':
    case 'details':
    case 'map':
    case 'calendar':
    case 'menu':
    case 'login':
    case 'report':
    default:
      return view.type;
  }
}

function View({ view }: { view: LiveAppView }) {
  const viewTypeClass = getViewTypeClass(view);

  return (
    <ViewContextProvider view={view}>
      <div className={cn('kn-view', `kn-${viewTypeClass}-view`)}>
        <ViewRender view={view} />
      </div>
    </ViewContextProvider>
  );
}

const memoized = memo(View);
export { memoized as View };
