import { type EquationField } from '@/types/schema/fields';
import { type KnackField } from '@/types/schema/KnackField';
import {
  blankOperators,
  containsOperators,
  dateTimeOperators,
  defaultOperators,
  geocodingOperators,
  isAnyOperators,
  isOperators,
  numberOperators,
  stringOperators,
  timeOperators,
  type KnackOperator
} from '@/types/schema/KnackOperator';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';

export function useFieldHelpers() {
  const { data: application } = useApplicationQuery();
  const { data: account } = useAccountQuery();

  const isEquationFieldWithDateFormat = (
    field: KnackField
  ): field is EquationField & {
    format: { equation_type: 'date'; date_result: 'date' };
  } =>
    field.type === 'equation' &&
    field.format?.equation_type === 'date' &&
    field.format?.date_result === 'date';

  const canFieldStoreDateValues = (field: KnackField) =>
    field.type === 'date_time' || isEquationFieldWithDateFormat(field);

  const getBaseFieldOperators = ({
    field,
    shouldExcludeNearOperator
  }: {
    field: KnackField;
    shouldExcludeNearOperator?: boolean;
  }): KnackOperator[] => {
    if (canFieldStoreDateValues(field)) {
      // For date/time fields that just store times, only show time operators
      if (field.type === 'date_time' && field.format.date_format === 'Ignore Date') {
        return timeOperators;
      }

      // Otherwise show date/time operators
      return dateTimeOperators;
    }

    if (field.type === 'address') {
      if (shouldExcludeNearOperator) {
        return defaultOperators;
      }

      const appHasSupportForGeocoding =
        application?.settings.hasGeocoding ||
        account?.productPlan?.id === 'trial' ||
        (account?.productPlan.level && account?.productPlan.level > 1);
      const fieldHasGeocodingEnabled = !!field.format.enable_geocoding;

      if (appHasSupportForGeocoding && fieldHasGeocodingEnabled) {
        return [...defaultOperators, ...geocodingOperators];
      }

      return defaultOperators;
    }

    switch (field.type) {
      case 'short_text':
      case 'paragraph_text':
      case 'rich_text':
      case 'concatenation':
      case 'name':
      case 'email':
      case 'phone':
        return defaultOperators;

      case 'number':
      case 'currency':
      case 'equation':
      case 'auto_increment':
      case 'sum':
      case 'min':
      case 'max':
      case 'average':
      case 'count':
      case 'timer':
      case 'rating':
        return numberOperators;

      case 'boolean':
        return [...isOperators, ...blankOperators];

      case 'multiple_choice':
      case 'connection':
      case 'user_roles':
        return [...isOperators, ...containsOperators, ...isAnyOperators, ...blankOperators];

      case 'file':
      case 'image':
      case 'signature':
        return [...blankOperators];

      case 'link':
        return [...blankOperators, ...isOperators];

      case 'password':
        return [...containsOperators, ...stringOperators];

      default:
        return [];
    }
  };

  return {
    canFieldStoreDateValues,
    getBaseFieldOperators
  };
}
