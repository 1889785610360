import { useQuery } from '@tanstack/react-query';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { type LiveAppView } from '@/types/schema/LiveAppView';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios, getAppBasedRequestHeaders } from '@/utils/axiosConfig';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { usePageContext } from '@/context/PageContext';

export interface ViewRecord {
  id?: string;
  [key: string]: any;
}

export type FormattedViewRecord = {
  values: ViewRecord;
  rawValues: ViewRecord;
};

type ViewRecordQueryOptions = {
  format: 'both' | 'raw';
};

const formatViewRecord = (data: ViewRecord) => {
  const formattedData: FormattedViewRecord = {
    values: {},
    rawValues: {}
  };

  Object.keys(data).forEach((key) => {
    if (key.endsWith('_raw')) {
      const newKey = key.replace('_raw', '');
      formattedData.rawValues[newKey] = data[key];

      // If the field is a phone field
      if (data[key]?.area) {
        formattedData.rawValues[newKey] = data[key].formatted;
      }

      // If the field is a timer, we need to format the data to match the expected in the frontend
      if (data[key]?.times) {
        [formattedData.rawValues[newKey]] = data[key].times;
      }
    } else {
      formattedData.values[key] = data[key];
    }
  });

  return formattedData;
};

async function getViewData(
  activePage: LiveAppPage,
  activePageRecordId: string | null,
  view: LiveAppView,
  options?: ViewRecordQueryOptions
) {
  const queryParams = options
    ? new URLSearchParams({
        ...options
      })
    : {};

  // If we are not in the page editor, we simply fetch the records from the view endpoint
  if (!isInternalBuilderIframe()) {
    const { data } = await axios.get<ViewRecord>(
      `v1/scenes/${activePage.key}/views/${view.key}/records/${activePageRecordId}`,
      {
        params: queryParams,
        withCredentials: true,
        headers: getAppBasedRequestHeaders()
      }
    );

    return formatViewRecord(data);
  }

  // Otherwise, we call a different endpoint that we pass the full `view` object to in the request body, that way the records
  // can be fetched based on the view fields being used in the preview, and new views that don't exist in the schema yet can display records.
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  // The endpoint uses a POST request to avoid the URL length limit when passing the view object
  const { data } = await axios.post<ViewRecord>(
    `/v1/live-app/${accountSlug}/${appSlug}/scenes/${activePage.key}/views/view-preview-records/${activePageRecordId}`,
    {
      view
    },
    {
      params: queryParams,
      withCredentials: true,
      headers: getAppBasedRequestHeaders()
    }
  );

  return formatViewRecord(data);
}

export function useViewRecordQuery({
  view,
  enabled = true,
  options
}: {
  view: LiveAppView;
  enabled?: boolean;
  options?: ViewRecordQueryOptions;
}) {
  const { activePage, activePageRecordId } = usePageContext();

  if (!activePage) {
    throw new Error('useViewRecordQuery must be used within a page context');
  }

  return useQuery({
    queryKey: [queryKeys.viewData, view.key, activePageRecordId],
    queryFn: async () => getViewData(activePage, activePageRecordId, view, options),
    enabled
  });
}
