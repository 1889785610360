import { type Dispatch, type SetStateAction } from 'react';
import { Button } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';

export function HamburgerToggle({
  isOpen,
  setIsOpen
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { theme } = useThemingContext();
  return (
    <Button
      intent="minimalStandalone"
      className="kn-main-nav-toggle p-2 focus:bg-navigation"
      onClick={() => setIsOpen((prevState) => !prevState)}
    >
      <div
        className={cn(
          'group flex h-[12px] w-[18px] items-center justify-center transition-[opacity] duration-150 ease-linear',
          { 'toggle-open': isOpen }
        )}
      >
        <div className="relative h-[12px] w-[18px]">
          <div
            className={cn(
              'group absolute top-[2px] -mt-[2px] block h-[2px] w-[18px] bg-gray-700 transition-[transform] duration-150 ease-in-out before:absolute before:top-[5px] before:block before:h-[2px] before:w-[18px] before:bg-gray-700 before:transition-[transform,opacity] before:duration-150 before:ease-in-out after:absolute after:top-[10px] after:block after:h-[2px] after:w-[18px] after:bg-gray-700 after:transition-[transform] after:duration-150 after:ease-in-out group-[.toggle-open]:translate-y-[5px] group-[.toggle-open]:rotate-45 group-[.toggle-open]:before:-translate-x-[8px] group-[.toggle-open]:before:-translate-y-[8px] group-[.toggle-open]:before:-rotate-45 group-[.toggle-open]:before:opacity-0 group-[.toggle-open]:after:-translate-y-[10px] group-[.toggle-open]:after:-rotate-90 dark:bg-gray-300 dark:before:bg-gray-300 dark:after:bg-gray-300',
              {
                'rounded-md before:rounded-md after:rounded-md':
                  theme.appearance.corners === 'rounded'
              }
            )}
          />
        </div>
      </div>
    </Button>
  );
}
