import { forwardRef } from 'react';
import { HiChevronDown as ChevronIcon } from 'react-icons/hi';
import {
  HiChevronRight as ChevronRightIcon,
  HiMiniArrowTopRightOnSquare as ExternalIcon
} from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

import type { LiveAppPage } from '@/types/schema/LiveAppPage';
import { cn } from '@/utils/tailwind';
import { LabelWithIcon } from '@/components/LabelWithIcon';

const NavItemWrapper = forwardRef<
  HTMLButtonElement,
  {
    item: LiveAppPage;
    setIsOpen?: (isOpen: boolean) => void;
    isDropdownMenu?: boolean;
    isSubTrigger?: boolean;
    isActive: boolean;
    isChild?: boolean;
    isMobile?: boolean;
  }
>((props, ref) => {
  const { item, setIsOpen, isDropdownMenu, isSubTrigger, isActive, isChild, isMobile } = props;
  let chevron = <ChevronRightIcon className="ml-auto size-4 shrink-0" />;
  if (isDropdownMenu) {
    chevron = (
      <ChevronIcon
        size={isMobile ? 20 : 16}
        className="shrink-0 transition-transform duration-300 group-data-[state=open]:rotate-180"
      />
    );
  }

  let buttonContent =
    isDropdownMenu || isSubTrigger ? (
      <span className="flex">
        <LabelWithIcon icon={item.icon?.icon} iconPosition={item.icon?.align || 'left'}>
          <span className="truncate">{item.name}</span>
          {chevron}
        </LabelWithIcon>
      </span>
    ) : (
      <Link to={item.slug}>
        <LabelWithIcon icon={item.icon?.icon} iconPosition={item.icon?.align || 'left'}>
          <span className="truncate">{item.name}</span>
        </LabelWithIcon>
      </Link>
    );

  if (item.isExternal) {
    buttonContent = (
      <a href={item.slug}>
        {item.name}
        <ExternalIcon className="ml-1" />
      </a>
    );
  }

  return (
    <Button
      intent="minimal"
      className={cn('kn-main-nav-item flex w-full justify-start whitespace-nowrap font-normal', {
        'bg-brand-subtle hover:bg-brand-subtle focus:bg-brand-subtle': isActive,
        'hover:bg-brand-muted': !isActive,
        'pl-6': isChild,
        'justify-between': isDropdownMenu && isMobile,
        'rounded-none': isMobile,
        'max-w-64': !isMobile
      })}
      onClick={() => setIsOpen?.(false)}
      ref={ref}
      asChild
    >
      {buttonContent}
    </Button>
  );
});

NavItemWrapper.displayName = 'NavItemWrapper';

export { NavItemWrapper };
