import { Navigate } from 'react-router-dom';

import { type LiveAppPageFull, type LiveAppPageRule } from '@/types/schema/LiveAppPage';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { usePageRulesMatchQuery } from '@/hooks/api/queries/usePageRulesMatchQuery';
import { usePageSegmentsHelpers } from '@/hooks/helpers/usePageSegmentsHelpers';
import { PageTitle } from '@/pages/page/PageTitle';
import { PageRulesMessages } from './PageRulesMessages';
import { PageSections } from './PageSections';

export function PageRender({ page }: { page: LiveAppPageFull }) {
  const { getRedirectToPagePathFromSlug, getPreviousPagePath, getActivePageSegment } =
    usePageSegmentsHelpers();

  // If the page has rules, we need to call the server to check which rules match
  const { data: matchingPageRules, isLoading: isLoadingPageRulesMatch } = usePageRulesMatchQuery({
    page,
    recordId: getActivePageSegment()?.recordId || undefined
  });

  if (isLoadingPageRulesMatch) {
    return null;
  }

  const viewKeysToHide: LiveAppViewKey[] = [];
  const pageRulesWithMessage: LiveAppPageRule[] = [];
  let urlToRedirectTo: string | undefined;
  let existingPageSlugToRedirectTo: string | undefined;
  let shouldRedirectToParentPage = false;

  matchingPageRules?.forEach((rule) => {
    if (rule.action === 'hide_views' && rule.view_keys) {
      viewKeysToHide.push(...rule.view_keys);
      return;
    }

    if (rule.action === 'message') {
      pageRulesWithMessage.push(rule);
      return;
    }

    if (rule.action === 'existing_page' && rule.existing_page) {
      existingPageSlugToRedirectTo = rule.existing_page;
      return;
    }

    if (rule.action === 'parent_page') {
      shouldRedirectToParentPage = true;
    }

    if (rule.action === 'url' && rule.url) {
      if (!rule.url.includes('http')) {
        urlToRedirectTo = `https://${rule.url}`;
      } else {
        urlToRedirectTo = rule.url;
      }
    }
  });

  if (urlToRedirectTo) {
    window.location.replace(urlToRedirectTo);
    return null;
  }

  if (existingPageSlugToRedirectTo) {
    return (
      <Navigate
        to={getRedirectToPagePathFromSlug(
          existingPageSlugToRedirectTo,
          page.sourceObjectKey ?? undefined
        )}
      />
    );
  }

  if (shouldRedirectToParentPage) {
    return <Navigate to={getPreviousPagePath()} />;
  }

  return (
    <>
      <PageRulesMessages pageRulesWithMessage={pageRulesWithMessage} />
      <PageTitle page={page} />
      <PageSections page={page} viewKeysToHide={viewKeysToHide} />
    </>
  );
}
