import { ThemeProvider } from '@knack/asterisk-react';

import { AppBackground } from './AppBackground';
import { AppCustomCode } from './AppCustomCode';
import { useThemingContext } from './context/ThemingContext';

export function LiveAppSetup({ children }: { children: React.ReactNode }) {
  const { theme } = useThemingContext();

  return (
    <ThemeProvider theme={theme}>
      {children}
      <AppBackground theme={theme} />
      <AppCustomCode />
    </ThemeProvider>
  );
}
