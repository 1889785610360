import { RichTextRenderer } from '@knack/asterisk-react';
import DOMPurify from 'dompurify';

import { type LiveAppView } from '@/types/schema/LiveAppView';
import { cn } from '@/utils/tailwind';

export function ViewHeaderSection({ view, className }: { view: LiveAppView; className?: string }) {
  const { title, description } = view;

  if (!title && !description) {
    return null;
  }

  const sanitizedValue = DOMPurify.sanitize(description || '');

  return (
    <div className={cn(className, 'kn-view-header')} data-testid="view-header-section">
      {title && (
        <h2 className="font-semibold text-emphasis" data-testid={`${view.id}-view-title`}>
          {title}
        </h2>
      )}
      {description && <RichTextRenderer dangerouslySetInnerHTML={{ __html: sanitizedValue }} />}
    </div>
  );
}
