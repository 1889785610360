import { t } from 'i18next';
import { z } from 'zod';

import { type AddressField, type DateTimeField } from '@/types/schema/fields';
import { type LiveAppApplicationPasswordOptions } from '@/types/schema/LiveAppApplication';
import { getPasswordValidationState } from '@/components/views/form/inputs/password/helper';

interface InputSchemaProps {
  isRequired: boolean;
  isReadOnly: boolean;
}
interface DateTimeInputSchemaProps extends InputSchemaProps {
  inputFormat: DateTimeField['format'];
}

interface AddressInputSchemaProps extends InputSchemaProps {
  inputFormat: AddressField['format'];
}

// Text Fields
export const shortTextInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  if (isRequired && !isReadOnly) {
    return z.string().min(1, t('components.views.form.field_required'));
  }
  return z.string();
};

export const paragraphTextInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  if (isRequired && !isReadOnly) {
    return z.string().min(1, t('components.views.form.field_required'));
  }
  return z.string();
};

export const richTextInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  if (isRequired && isReadOnly) {
    return z.string().min(1, t('components.views.form.field_required'));
  }
  return z.string();
};

// Number Fields
export const numberInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  if (isRequired && !isReadOnly) {
    return z
      .string()
      .min(1, t('components.views.form.field_required'))
      .or(z.number().min(1, t('components.views.form.field_required')));
  }
  return z.string().or(z.number());
};

export const currencyInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  if (isRequired && !isReadOnly) {
    return z.string().min(1, t('components.views.form.field_required'));
  }
  return z.string();
};

export const equationInputSchema = () => z.string().or(z.number());

// Choice Fields
export const multiChoiceInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  const multiChoiceSchema = z.string().or(z.array(z.string()));

  if (isRequired && !isReadOnly) {
    return multiChoiceSchema.refine((value) => value && value !== 'kn-blank', {
      message: t('components.views.form.field_required'),
      path: ['']
    });
  }

  return multiChoiceSchema;
};

export const booleanInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  if (isRequired && !isReadOnly) {
    return z.union([z.enum(['True', 'Yes', 'On']), z.literal(true)]);
  }
  return z.union([z.enum(['True', 'False', 'Yes', 'No', 'On', 'Off']), z.boolean()]);
};

// Date Fields
export const dateTimeInputSchema = ({
  isRequired,
  isReadOnly,
  inputFormat
}: DateTimeInputSchemaProps) => {
  const dateTimeSchema = z.object({
    date: z.string(),
    am_pm: z.enum(['AM', 'PM']).optional(),
    hours: z.number().or(z.string()).optional(),
    minutes: z.number().or(z.string()).optional(),
    repeat: z
      .object({
        frequency: z.enum(['daily', 'weekly', 'monthly', 'yearly']),
        interval: z.string(),
        SU: z.boolean(),
        MO: z.boolean(),
        TU: z.boolean(),
        WE: z.boolean(),
        TH: z.boolean(),
        FR: z.boolean(),
        SA: z.boolean(),
        repeatby: z.enum(['dom', 'dow']),
        endson: z.enum(['never', 'limit', 'date']),
        end_count: z.string().optional(),
        end_date: z.string().optional()
      })
      .optional(),
    all_day: z.boolean().optional()
  });

  if (inputFormat.calendar) {
    return dateTimeSchema.merge(
      z.object({
        to: z.object({
          date: z.string(),
          am_pm: z.enum(['AM', 'PM']).optional(),
          hours: z.number().or(z.string()).optional(),
          minutes: z.number().or(z.string()).optional()
        })
      })
    );
  }

  if (isRequired && !isReadOnly) {
    return dateTimeSchema.refine(
      ({ date, hours, minutes, am_pm }) => date && hours && minutes && am_pm,
      {
        message: t('components.views.form.field_required')
      }
    );
  }

  if (inputFormat.date_format === 'Ignore Date') {
    return dateTimeSchema.omit({ date: true });
  }

  if (inputFormat.time_format === 'Ignore Time') {
    return dateTimeSchema.omit({ hours: true, minutes: true, am_pm: true });
  }

  return dateTimeSchema;
};

export const timerInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  const timeSchema = z
    .object({
      from: z
        .object({
          date: z.string(),
          hours: z.number().or(z.string()),
          minutes: z.number().or(z.string()),
          am_pm: z.string()
        })
        .partial(),
      to: z.object({
        date: z.string(),
        hours: z.number().or(z.string()),
        minutes: z.number().or(z.string()),
        am_pm: z.string()
      })
    })
    .partial()
    .or(z.string()); // If no value is set it can comes as string

  if (isRequired && !isReadOnly) {
    return timeSchema.refine(
      (val) => {
        if (typeof val === 'string') return false;
        return val.from?.date && val.to?.date;
      },
      {
        message: t('components.views.form.field_required')
      }
    );
  }

  return timeSchema;
};

// File Fields
export const fileInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  // When updating a file input, the value comes as an object
  const assetObject = z.object({
    id: z.string(),
    application_id: z.string(),
    s3: z.boolean(),
    type: z.string(),
    source: z.string().nullable(),
    filename: z.string(),
    url: z.string(),
    thumb_url: z.string(),
    size: z.number(),
    field_key: z.string()
  });
  if (isRequired && !isReadOnly) {
    return z.string().min(1, t('components.views.form.field_required')).or(assetObject);
  }
  return z.string().or(assetObject).optional();
};

// Personal Fields
export const nameInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  const nameSchema = z
    .object({
      title: z.string(),
      first: z.string(),
      middle: z.string(),
      last: z.string()
    })
    .partial();

  if (isRequired && !isReadOnly) {
    return nameSchema.refine(({ first, last }) => first?.trim() || last?.trim(), {
      message: t('components.views.form.one_required'),
      path: ['']
    });
  }

  return nameSchema;
};

export const emailInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  const emailSchema = z
    .object({
      email: z.string().email().or(z.literal('')),
      label: z.string().nullable()
    })
    .partial();

  if (isRequired && !isReadOnly) {
    return emailSchema.refine(({ email }) => email, {
      message: t('components.views.form.field_required'),
      path: ['email']
    });
  }

  return emailSchema;
};

export const addressInputSchema = ({
  isRequired,
  isReadOnly,
  inputFormat
}: AddressInputSchemaProps) => {
  const addressSchema = z
    .object({
      street: z.string().nullable(),
      street2: z.string().nullable(),
      city: z.string().nullable(),
      state: z.string().nullable(),
      zip: z.string().nullable(),
      country: z.string().nullable(),
      latitude: z.union([z.string(), z.number()]).nullable(),
      longitude: z.union([z.string(), z.number()]).nullable()
    })
    .partial();

  if (isRequired && !isReadOnly) {
    if (inputFormat.input === 'lat_long') {
      return addressSchema.refine(({ latitude, longitude }) => latitude || longitude, {
        message: t('components.views.form.field_required')
      });
    }
    // TODO: Add superRefine to control each input validation - Post Pilot 🙏🏼
    // A validation is done in the backend anyway, but with superRefine we will show the errors in each Input without making a call
    return addressSchema.refine(
      ({ street, city, state, zip }) =>
        street?.trim() || city?.trim() || state?.trim() || zip?.trim(),
      {
        message: t('components.views.form.address_input.field_required'),
        path: ['']
      }
    );
  }
  return addressSchema;
};

export const phoneInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  if (isRequired && !isReadOnly) {
    return z.string().min(1, t('components.views.form.field_required'));
  }
  return z.string().optional();
};

// Other Fields

export const linkInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  if (isRequired && !isReadOnly) {
    return z.object({
      url: z.string().min(1, t('components.views.form.field_required')),
      label: z.string().nullable().optional()
    });
  }
  return z
    .object({
      url: z.string(),
      label: z.string().nullable()
    })
    .partial();
};

export const signatureInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  if (isRequired && !isReadOnly) {
    return z
      .object({
        base30: z.string(),
        svg: z.string()
      })
      .or(z.string())
      .nullable()
      .refine((value) => (typeof value !== 'string' ? value?.base30 : value), {
        message: t('components.views.form.field_required')
      });
  }

  return z
    .object({
      base30: z.string(),
      svg: z.string()
    })
    .or(z.string())
    .nullable();
};

export const ratingInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  if (isRequired && !isReadOnly) {
    return z
      .string()
      .min(1, t('components.views.form.field_required'))
      .or(z.number().min(1, t('components.views.form.field_required')));
  }

  return z.string().or(z.number()).optional();
};

export const connectionInputSchema = ({ isRequired, isReadOnly }: InputSchemaProps) => {
  const connectionSchema = z.object({
    id: z.string(),
    identifier: z.string().or(z.number())
  });

  if (isRequired && !isReadOnly) {
    return z.array(connectionSchema).min(1, t('components.views.form.field_required'));
  }

  return z.array(connectionSchema);
};

export const passwordInputSchema = (
  action_authenticate: boolean | undefined,
  passwordOptions?: LiveAppApplicationPasswordOptions
) => {
  const schema = z
    .object({
      password: z.string().min(1, t('components.views.form.field_required')),
      confirmPassword: z.string(),
      ...(action_authenticate && {
        currentPassword: z.string().min(1, t('components.views.form.field_required'))
      })
    })
    .superRefine(({ password, confirmPassword, currentPassword }, context) => {
      const passwordValidationResult = getPasswordValidationState(password, passwordOptions);

      if (
        passwordValidationResult &&
        Object.values(passwordValidationResult).some((result) => result === 'error')
      ) {
        context.addIssue({
          code: 'custom',
          message: t('components.views.form.password_input.error'),
          path: ['password']
        });
      }

      // Check if passwords match
      if (password !== confirmPassword) {
        context.addIssue({
          code: 'custom',
          message: t('components.views.form.password_input.same_password'),
          path: ['confirmPassword']
        });
      }

      if (password === currentPassword) {
        context.addIssue({
          code: 'custom',
          message: t('components.views.form.password_input.current_password_same_error'),
          path: ['currentPassword']
        });
      }
    });

  return schema;
};

export const userRolesInputSchema = () => z.array(z.string()).optional();
