import { Controller, useFormContext } from 'react-hook-form';
import { Select } from '@knack/asterisk-react';

import { type BooleanField } from '@/types/schema/fields';
import { type ViewFilters } from '@/hooks/useViewFilters';
import {
  booleanOptions,
  getFormattedBooleanDefaultValue
} from '@/components/views/form/inputs/boolean/helper';

interface FilterBooleanInputProps {
  field: BooleanField;
  criteriaIndex: number;
}

export function FilterBooleanInput({ field, criteriaIndex }: FilterBooleanInputProps) {
  const { format: booleanFormat } = field;

  const { control } = useFormContext<ViewFilters>();

  return (
    <Controller
      control={control}
      name={`rules.${criteriaIndex}.value`}
      render={({ field: { value, onChange } }) => {
        const fieldValue =
          typeof value === 'boolean'
            ? getFormattedBooleanDefaultValue(value, booleanFormat)
            : booleanOptions[booleanFormat.format][0];

        return (
          <Select value={fieldValue} onValueChange={onChange}>
            <Select.Trigger
              id={`view-filters-${criteriaIndex}-boolean-input`}
              className="h-9 w-full"
            />
            <Select.Content>
              <Select.Group>
                {booleanOptions[booleanFormat.format].map((option, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Select.Item key={index} value={option}>
                    {option}
                  </Select.Item>
                ))}
              </Select.Group>
            </Select.Content>
          </Select>
        );
      }}
    />
  );
}
