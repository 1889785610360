import { useCallback, useEffect, useState } from 'react';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import {
  type DetailsDisplayRuleAction,
  type DetailsView,
  type DetailsViewDisplayRule
} from '@/types/schema/views/DetailsView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isEveryCriteriaMet } from '@/utils/criteriaRules';
import { useViewContext } from '@/components/views/ViewContext';

export interface FormattedDetailsDisplayRuleAction {
  label: string;
  shouldHideField: boolean;
  bgColor: string;
  textColor: string;
  bold?: boolean;
  italic?: boolean;
  strikethrough?: boolean;
  color?: string;
}

export function useDetailsDisplayRules(
  recordValues: ViewRecord | undefined,
  displayRules: DetailsViewDisplayRule[] | undefined
) {
  const [activeDisplayRuleActions, setActiveDisplayRuleActions] = useState<
    DetailsDisplayRuleAction[]
  >([]);

  const { sourceTable } = useViewContext<DetailsView>();

  const processDisplayRuleActions = useCallback(
    (fieldKey: KnackFieldKey): FormattedDetailsDisplayRuleAction => {
      const defaultRules: FormattedDetailsDisplayRuleAction = {
        label: '',
        shouldHideField: false,
        bgColor: '',
        textColor: '',
        bold: false,
        italic: false,
        strikethrough: false,
        color: ''
      };

      const currentRules = activeDisplayRuleActions.filter(
        (ruleAction) => ruleAction.field === fieldKey
      );

      if (currentRules.length) {
        const extractedDisplayRules = currentRules.reduce((acc, action) => {
          switch (action.action) {
            case 'show':
              acc.shouldHideField = false;
              break;

            case 'hide':
              acc.shouldHideField = true;
              break;

            case 'label':
              acc.label = action.value;
              break;

            case 'bg-color':
              acc.bgColor = action.value;
              break;

            case 'text-color':
              acc.textColor = action.color || '';
              break;

            case 'text-style':
              acc.bold = action.bold;
              acc.italic = action.italic;
              acc.strikethrough = action.strikethrough;
              break;

            default:
              break;
          }
          return acc;
        }, defaultRules);

        return extractedDisplayRules;
      }

      return defaultRules;
    },
    [activeDisplayRuleActions]
  );

  const checkActiveDetailsDisplayRules = useCallback(
    (data: ViewRecord, rules: DetailsViewDisplayRule[]) => {
      const actions: DetailsDisplayRuleAction[] = [];

      rules.forEach((rule) => {
        if (isEveryCriteriaMet(data, sourceTable.fields, rule.criteria)) {
          rule.actions.forEach((action) => {
            const isExistingType = actions.some((a) => a.action === action.action);

            // If the action type already exists, only keep the last one
            if (isExistingType) {
              const existingAction = actions.find((a) => a.action === action.action);

              if (existingAction) {
                if (existingAction.field === action.field) {
                  actions.splice(actions.indexOf(existingAction), 1);
                }
              }
            }
            actions.push(action);
          });
        }
      });

      setActiveDisplayRuleActions(actions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceTable.fields, activeDisplayRuleActions]
  );

  useEffect(() => {
    if (displayRules && recordValues) {
      checkActiveDetailsDisplayRules(recordValues, displayRules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordValues]);

  return {
    activeDisplayRuleActions,
    processDisplayRuleActions
  };
}
