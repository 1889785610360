import { useTranslation } from 'react-i18next';
import { HiArrowUpOnSquare as ExportIcon } from 'react-icons/hi2';
import { Button, DropdownMenu } from '@knack/asterisk-react';

import {
  useExportViewRecords,
  type ExportViewRecordsFormat
} from '@/hooks/api/queries/useExportViewRecordsQuery';
import { useViewSearchParams, type ViewWithSearchParams } from '@/hooks/useViewSearchParams';
import { cn } from '@/utils/tailwind';
import { ListViewFiltersPopover } from '@/components/views/list/ListViewFiltersPopover';
import { MapViewFiltersPopover } from '@/components/views/map/filters/MapViewFiltersPopover';
import { TableViewFiltersPopover } from '@/components/views/table/TableViewFiltersPopover';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewFiltersChips } from './ViewFiltersChips';
import { ViewMenuFilters } from './ViewMenuFilters';
import { ViewSearchInput } from './ViewSearchInput';

export function ViewToolbarSection({ className }: { className?: string }) {
  const [t] = useTranslation();

  const { view, sourceTable } = useViewContext<ViewWithSearchParams>();
  const { activeViewFilters } = useViewSearchParams(view);
  const exportViewRecords = useExportViewRecords();

  let filterType;
  let isTableSearchEnabled;
  let shouldAllowExport;
  let menuFilters;

  if (view.type === 'map') {
    filterType = view.details.filter_type;
    menuFilters = view.details.menu_filters;
  } else {
    filterType = view.filter_type;
    isTableSearchEnabled = view.keyword_search;
    shouldAllowExport = view.allow_exporting;
    menuFilters = view.menu_filters;
  }

  const hasMenuFilters = menuFilters && menuFilters?.length > 0 && filterType === 'menu';
  const hasActiveViewFilters = activeViewFilters && activeViewFilters.rules.length > 0;
  const shouldNotRenderToolbarSection =
    !shouldAllowExport && filterType !== 'fields' && !isTableSearchEnabled && !hasMenuFilters;

  const handleExportViewRecords = async (fileFormat: ExportViewRecordsFormat) => {
    await exportViewRecords({
      viewKey: view.key,
      fileFormat,
      fileName: `${view.title}-exported-records`
    });
  };

  if (shouldNotRenderToolbarSection) {
    return null;
  }

  return (
    <div className={className}>
      {(shouldAllowExport || filterType === 'fields' || isTableSearchEnabled) && (
        <div
          className={cn('flex flex-row flex-wrap items-center gap-2 md:justify-between', {
            'md:justify-end': filterType === 'none' && isTableSearchEnabled
          })}
        >
          {(shouldAllowExport || filterType === 'fields') && (
            <div className="flex flex-row flex-wrap items-center gap-2">
              {shouldAllowExport && (
                <DropdownMenu>
                  <DropdownMenu.Trigger asChild>
                    <Button
                      data-testid={`${view.key}-export-button`}
                      intent="secondary"
                      className="gap-2 text-default md:w-auto"
                    >
                      <ExportIcon size={16} />
                      <span className="hidden md:inline">{t('actions.export')}</span>
                    </Button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content align="start">
                    <DropdownMenu.Item onSelect={() => handleExportViewRecords('csv')}>
                      {t('components.views.table.export_csv')}
                    </DropdownMenu.Item>
                    <DropdownMenu.Item onSelect={() => handleExportViewRecords('text')}>
                      {t('components.views.table.export_txt')}
                    </DropdownMenu.Item>
                    <DropdownMenu.Item onSelect={() => handleExportViewRecords('json')}>
                      {t('components.views.table.export_json')}
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </DropdownMenu>
              )}
              {filterType === 'fields' && (
                <>
                  {view.type === 'table' && (
                    <TableViewFiltersPopover view={view} sourceObject={sourceTable} />
                  )}
                  {view.type === 'list' && (
                    <ListViewFiltersPopover view={view} sourceObject={sourceTable} />
                  )}
                  {view.type === 'map' && (
                    <MapViewFiltersPopover view={view} sourceObject={sourceTable} />
                  )}
                </>
              )}
              {hasActiveViewFilters && !hasMenuFilters && (
                <div className="hidden md:block">
                  <ViewFiltersChips activeViewFilters={activeViewFilters} />
                </div>
              )}
            </div>
          )}
          {isTableSearchEnabled && (
            <div className="flex-1 md:flex-none">
              <ViewSearchInput />
            </div>
          )}
        </div>
      )}
      {hasMenuFilters && (
        <div className="mt-2 w-fit">
          <ViewMenuFilters menuFilters={menuFilters} />
        </div>
      )}
    </div>
  );
}
