import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type DetailsView } from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import type { FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { useViewContext } from '@/components/views/ViewContext';
import { DetailsColumn } from './DetailsColumn';

type DetailsOuterColumnsProps = {
  record: FormattedViewRecord | undefined;
  className?: string;
};

export function DetailsOuterColumns({ record, className }: DetailsOuterColumnsProps) {
  const { view } = useViewContext<ListView | DetailsView | MapView | CalendarView>();

  const outerColumns =
    view.type === 'map' || view.type === 'calendar' ? view.details.columns : view.columns;

  return (
    <div
      className={cn(
        'flex w-full flex-wrap gap-4 sm:flex-nowrap',
        {
          'max-w-[600px]': outerColumns.length === 1
        },
        className
      )}
    >
      {outerColumns.map((outerColumn) => (
        <div
          key={outerColumn.id}
          className={cn('w-full min-w-0 flex-1', {
            'basis-1/2': outerColumn.width === 50,
            'basis-1/3': outerColumn.width === 33,
            'basis-2/3': outerColumn.width === 66,
            'basis-1/4': outerColumn.width === 25,
            'basis-3/4': outerColumn.width === 75
          })}
        >
          <DetailsColumn column={outerColumn.groups[0].columns[0]} record={record} />
        </div>
      ))}
    </div>
  );
}
