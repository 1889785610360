import { useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackField } from '@/types/schema/KnackField';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { useGoogleApi } from '@/hooks/useGoogleApi';
import { getRgbChannels } from '@/utils/colors';
import { isSomeCriteriaMet } from '@/utils/criteriaRules';
import { cn } from '@/utils/tailwind';
import { MapEmptyState } from '@/components/views/map/engine/MapEmptyState';
import { MapPopup } from '@/components/views/map/engine/MapPopup';
import { MAP_DEFAULT_COORDINATES } from '@/components/views/map/engine/providers/constants';
import { useThemingContext } from '@/context/ThemingContext';

type GoogleMapProps = {
  formattedViewRecords: FormattedViewRecord[];
  selectedRecord?: FormattedViewRecord;
  addressFieldKey: string;
  titleFieldKey?: string;
  pinColorDefault: string;
  pinColors: KnackCriteria[];
  fields: KnackField[];
  onSelectRecord?: (record: FormattedViewRecord) => void;
  onUnselectRecord?: () => void;
  defaultAddress?: { latitude: number; longitude: number };
  googleMapId?: string;
};

function renderPins({
  map,
  formattedViewRecords: records,
  addressFieldKey,
  selectedRecord,
  pinColorDefault,
  pinColors,
  fields,
  onSelectRecord,
  titleFieldKey
}: GoogleMapProps & { map: google.maps.Map }): Record<
  string,
  google.maps.marker.AdvancedMarkerElement
> {
  const pins: Record<string, google.maps.marker.AdvancedMarkerElement> = {};
  const bounds = new google.maps.LatLngBounds();

  records.forEach((record) => {
    if (!record.values.id) return;

    const address = record.rawValues[addressFieldKey];

    if (isNil(address) || isNil(address.latitude) || isNil(address.longitude)) return;

    let pinColor = pinColorDefault;
    isSomeCriteriaMet(record.rawValues, fields, pinColors, ({ criteria }) => {
      pinColor = criteria.color || '';
    });

    const position = new google.maps.LatLng(address.latitude, address.longitude);

    const pin = new google.maps.marker.PinElement({
      background: pinColor,
      borderColor: getRgbChannels(pinColor),
      glyphColor: getRgbChannels(pinColor)
    });

    const marker = new google.maps.marker.AdvancedMarkerElement({
      position,
      map,
      content: pin.element
    });

    marker.addListener('click', () => {
      const infoWindow = new google.maps.InfoWindow({
        content: renderToString(
          <MapPopup
            record={record}
            addressFieldKey={addressFieldKey}
            titleFieldKey={titleFieldKey}
          />
        )
      });
      infoWindow.open(map, marker);
      if (onSelectRecord) onSelectRecord(record);
    });

    pins[record.values.id] = marker;
    bounds.extend(position);
  });

  if (!bounds.isEmpty() && !selectedRecord) {
    map.fitBounds(bounds, 100);
    const zoom = map.getZoom();
    if (zoom && zoom > 13) map.setZoom(13);
  }

  return pins;
}

function GoogleMapContent({
  formattedViewRecords,
  selectedRecord,
  addressFieldKey,
  titleFieldKey,
  pinColorDefault,
  pinColors,
  fields,
  onSelectRecord,
  onUnselectRecord,
  defaultAddress,
  googleMapId
}: GoogleMapProps) {
  const { theme } = useThemingContext();
  const mapContainer = useRef<HTMLDivElement>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const pinsRef = useRef<Record<string, google.maps.marker.AdvancedMarkerElement>>({});
  const previousInfoWindowRef = useRef<google.maps.InfoWindow>();

  useEffect(() => {
    if (mapRef.current || !mapContainer.current) return undefined;

    mapRef.current = new google.maps.Map(mapContainer.current, {
      center: {
        lat: defaultAddress?.latitude || MAP_DEFAULT_COORDINATES.latitude,
        lng: defaultAddress?.longitude || MAP_DEFAULT_COORDINATES.longitude
      },
      zoom: 13,
      mapTypeControl: false,
      fullscreenControl: false,
      mapId: googleMapId || 'DEMO_APP_ID',
      streetViewControl: false
    });

    return () => {
      Object.values(pinsRef.current).forEach((pin) => pin.remove());
      pinsRef.current = {};
      mapRef.current = null;
    };
  }, [defaultAddress, googleMapId]);

  useEffect(() => {
    if (!mapRef.current) return;

    Object.values(pinsRef.current).forEach((pin) => {
      // eslint-disable-next-line no-param-reassign
      pin.map = null;
    });
    pinsRef.current = {};
    previousInfoWindowRef.current?.close();
    previousInfoWindowRef.current = undefined;

    pinsRef.current = renderPins({
      map: mapRef.current,
      formattedViewRecords,
      selectedRecord,
      addressFieldKey,
      titleFieldKey,
      pinColorDefault,
      pinColors,
      fields,
      onSelectRecord
    });

    mapRef.current.addListener('click', () => {
      previousInfoWindowRef.current?.close();
      previousInfoWindowRef.current = undefined;
      if (onUnselectRecord) onUnselectRecord();
    });
  }, [
    formattedViewRecords,
    addressFieldKey,
    titleFieldKey,
    pinColorDefault,
    pinColors,
    fields,
    selectedRecord,
    onSelectRecord,
    onUnselectRecord
  ]);

  useEffect(() => {
    if (!mapRef.current || !selectedRecord || !selectedRecord.values.id) return;

    const selectedPin = pinsRef.current[selectedRecord.values.id];
    if (selectedPin) {
      const infoWindow = new google.maps.InfoWindow({
        content: renderToString(
          <MapPopup
            record={selectedRecord}
            addressFieldKey={addressFieldKey}
            titleFieldKey={titleFieldKey}
          />
        )
      });

      previousInfoWindowRef.current?.close();
      infoWindow.open(mapRef.current, selectedPin);
      previousInfoWindowRef.current = infoWindow;

      if (selectedPin.position) {
        mapRef.current.panTo(selectedPin.position);
      }
    }
  }, [selectedRecord, addressFieldKey, titleFieldKey]);

  return (
    <div
      ref={mapContainer}
      className={cn('size-full', {
        'rounded-lg': theme.appearance.corners === 'rounded'
      })}
    />
  );
}

export function GoogleMap({
  formattedViewRecords,
  selectedRecord,
  addressFieldKey,
  titleFieldKey,
  pinColorDefault,
  pinColors,
  fields,
  onSelectRecord,
  onUnselectRecord,
  defaultAddress,
  googleMapId
}: GoogleMapProps) {
  const [t] = useTranslation();
  const { isLoading: isLoadingGoogleApi } = useGoogleApi();

  if (isLoadingGoogleApi) {
    return (
      <MapEmptyState>
        <p>{t('components.views.map.searching')}</p>
      </MapEmptyState>
    );
  }

  return (
    <GoogleMapContent
      formattedViewRecords={formattedViewRecords}
      selectedRecord={selectedRecord}
      addressFieldKey={addressFieldKey}
      titleFieldKey={titleFieldKey}
      pinColorDefault={pinColorDefault}
      pinColors={pinColors}
      fields={fields}
      onSelectRecord={onSelectRecord}
      onUnselectRecord={onUnselectRecord}
      defaultAddress={defaultAddress}
      googleMapId={googleMapId}
    />
  );
}
