import { type ReactNode } from 'react';

import { type KnackObjectResponse } from '@/types/schema/KnackObject';
import { type LiveAppView } from '@/types/schema/LiveAppView';
import { ViewContextProvider } from '@/components/views/ViewContext';
import { AuthFlowContextProvider } from '@/pages/page/AuthFlowContext';
import { tableDemo } from './tableDemo';

export function DemoView({
  view,
  children,
  sourceTable
}: {
  view: LiveAppView;
  children: ReactNode;
  sourceTable?: KnackObjectResponse;
}) {
  return (
    <AuthFlowContextProvider>
      <ViewContextProvider view={view} demoSourceTable={sourceTable || tableDemo}>
        {children}
      </ViewContextProvider>
    </AuthFlowContextProvider>
  );
}
