import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { usePageSegmentsHelpers } from '@/hooks/helpers/usePageSegmentsHelpers';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { usePageContext } from '@/context/PageContext';

interface UpdateOptionParams {
  pageKey: string;
  pageSlug: string;
  viewKey: string;
  recordId: string | undefined;
  pageUrl?: string;
  parentPageUrl?: string;
  data: {
    [key: string]: any;
  };
}

interface UseUpdateRecordMutationParams {
  data: {
    [key: string]: any;
  };
  recordId: string | undefined;
}

type RecordMutationResponse = {
  record: ViewRecord;
  submit_key: string | boolean;
};

export async function editRecord({
  pageKey,
  pageSlug,
  viewKey,
  pageUrl,
  parentPageUrl,
  recordId,
  data
}: UpdateOptionParams) {
  if (!recordId) throw new Error('Record ID is required');
  const isActionLinkUpdate = data.action_link_index;

  const url = `/v1/scenes/${pageKey}/views/${viewKey}/records/${recordId}/`;

  const formattedData = {
    crumbtrails: {
      [`${pageSlug}_id`]: recordId
    },
    _id: recordId,

    // These two properties are used when processing email rules, since the rules might use template variables that refer to the page and parent page.
    parent_url: parentPageUrl,
    url: pageUrl,

    ...data
  };

  const { data: responseData } = await axios.put<RecordMutationResponse>(
    url,
    isActionLinkUpdate ? data : formattedData,
    {
      withCredentials: true
    }
  );

  return responseData;
}

export function useUpdateRecordMutation({ viewKey }: { viewKey: string }) {
  const queryClient = useQueryClient();

  const { activePage } = usePageContext();
  const { getPreviousPageFullUrl } = usePageSegmentsHelpers();

  if (!activePage) {
    throw new Error('Active page not found');
  }

  return useMutation({
    mutationFn: ({ data, recordId }: UseUpdateRecordMutationParams) =>
      editRecord({
        pageKey: activePage.key,
        pageSlug: activePage.slug,
        pageUrl: window.location.href,
        parentPageUrl: getPreviousPageFullUrl(),
        viewKey,
        recordId,
        data
      }),
    onSuccess: async ({ record }) => {
      // Invalidate the queries that are inactive and contain the record id
      // e.g We update with inline editing in Table View and then we go to edit record with Form View
      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(record.id),
        refetchType: 'inactive'
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.viewData]
      });
    }
  });
}
