import { useFormContext, type FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Tooltip } from '@knack/asterisk-react';

import { type FormViewFieldInput } from '@/types/schema/views/FormView';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { useViewContext } from '@/components/views/ViewContext';
import { useFormDisplayRulesContext } from './FormDisplayRulesContext';
import { FormInputWrapper } from './FormInputWrapper';
import { AddressInput } from './inputs/address/AddressInput';
import { BooleanInput } from './inputs/boolean/BooleanInput';
import { ConnectionInput } from './inputs/connection/ConnectionInput';
import { CurrencyInput } from './inputs/CurrencyInput';
import { DateTimeInput } from './inputs/date-time/DateTimeInput';
import { EmailInput } from './inputs/EmailInput';
import { EquationInput } from './inputs/equation/EquationInput';
import { FileInput } from './inputs/FileInput';
import { LinkInput } from './inputs/LinkInput';
import { MultipleChoiceInput } from './inputs/multiple-choice/MultipleChoiceInput';
import { NameInput } from './inputs/NameInput';
import { NumberInput } from './inputs/NumberInput';
import { ParagraphTextInput } from './inputs/ParagraphTextInput';
import { PasswordInput } from './inputs/password/PasswordInput';
import { PhoneInput } from './inputs/PhoneInput';
import { RatingInput } from './inputs/RatingInput';
import { RichTextInput } from './inputs/RichTextInput';
import { ShortTextInput } from './inputs/ShortTextInput';
import { SignatureInput } from './inputs/signature/SignatureInput';
import { TimerInput } from './inputs/timer/TimerInput';
import { UserRolesInput } from './inputs/UserRolesInput';

function FormFieldInputByType({
  input,
  isReadOnly // Determines if the input is read-only, based not only on the `read_only` property of the input, but also on the field (server-computed/read-only fields)
}: {
  input: FormViewFieldInput;
  isReadOnly?: boolean;
}) {
  switch (input.type) {
    case 'short_text':
      return <ShortTextInput input={input} isReadOnly={isReadOnly} />;
    case 'paragraph_text':
      return <ParagraphTextInput input={input} isReadOnly={isReadOnly} />;
    case 'rich_text':
      return <RichTextInput input={input} isReadOnly={isReadOnly} />;
    case 'number':
      return <NumberInput input={input} isReadOnly={isReadOnly} />;
    case 'currency':
      return <CurrencyInput input={input} isReadOnly={isReadOnly} />;
    case 'equation':
      return <EquationInput input={input} isReadOnly={isReadOnly} />;
    case 'multiple_choice':
      return <MultipleChoiceInput input={input} isReadOnly={isReadOnly} />;
    case 'boolean': {
      return <BooleanInput input={input} isReadOnly={isReadOnly} />;
    }
    case 'file':
    case 'image': {
      return <FileInput input={input} isReadOnly={isReadOnly} />;
    }
    case 'name': {
      return <NameInput input={input} isReadOnly={isReadOnly} />;
    }
    case 'email': {
      return <EmailInput input={input} isReadOnly={isReadOnly} />;
    }
    case 'date_time': {
      return <DateTimeInput input={input} isReadOnly={isReadOnly} />;
    }
    case 'timer': {
      return <TimerInput input={input} isReadOnly={isReadOnly} />;
    }
    case 'address': {
      return <AddressInput input={input} />;
    }
    case 'phone': {
      return <PhoneInput input={input} />;
    }
    case 'link': {
      return <LinkInput input={input} isReadOnly={isReadOnly} />;
    }
    case 'signature': {
      return <SignatureInput input={input} />;
    }
    case 'rating': {
      return <RatingInput input={input} />;
    }
    case 'connection': {
      return <ConnectionInput input={input} isReadOnly={isReadOnly} />;
    }
    case 'password': {
      return <PasswordInput input={input} />;
    }
    case 'user_roles': {
      return <UserRolesInput input={input} />;
    }
    default:
      return null;
  }
}

export function FormFieldInput({
  input,
  isInlineEdit = false
}: {
  input: FormViewFieldInput;
  isInlineEdit?: boolean;
}) {
  const [t] = useTranslation();
  const form = useFormContext();
  const { view, sourceTable } = useViewContext();
  const displayRulesContext = useFormDisplayRulesContext({
    enabled: !isInlineEdit && !isInternalBuilderIframe() && view.type === 'form'
  });

  const error = form.formState.errors[input.field.key] as FieldError | undefined;

  const field = sourceTable?.fields.find((f) => f.key === input.field.key);

  if (!field) {
    return null;
  }

  const isReadOnly = input.read_only || field?.read_only;

  // The input type can sometimes be incorrect due to malformed schema in form views (for instance, 'short_text' can come as 'text_input', 'address' can come as 'text_area', ' etc).
  // Due to backwards compatibility, we need to force the input type to be the same as the field type
  // eslint-disable-next-line no-param-reassign
  input.type = field.type as FormViewFieldInput['type'];

  // We can't rely on input.format because many times it doesn't come in the schema, or when it comes, it comes outdated, we overwrite it with the field format
  // eslint-disable-next-line no-param-reassign
  input.format = field.format;

  let shouldHideInput = false;
  let inputLabel = '';

  // For Inline Editing we don't want to process Display rules
  if (!isInlineEdit && displayRulesContext) {
    const processedDisplayRuleActions = displayRulesContext.processDisplayRulesActions(input);

    shouldHideInput = processedDisplayRuleActions.isHidden;
    inputLabel = processedDisplayRuleActions.inputLabel;
  }

  if (shouldHideInput) {
    return null;
  }

  // If label_authenticate is empty, we should set the default label to the "Current Password"
  if (input.type === 'password' && input.action_authenticate) {
    inputLabel = input.label_authenticate
      ? input.label_authenticate
      : t('components.views.form.password_input.current_password');
  }

  // Add the input component according to the field type to a variable to know if it is an unsupported field type and not render it
  const InputField = FormFieldInputByType({ input, isReadOnly });

  if (!InputField) {
    return null;
  }

  return (
    <FormInputWrapper>
      {input.label && (
        <Label
          htmlFor={`${view.key}-${input.id}`}
          intent={error ? 'destructive' : undefined}
          className="font-medium"
          isRequired={field.required && !isReadOnly}
        >
          {inputLabel || input.label}
        </Label>
      )}
      {isReadOnly ? (
        <Tooltip>
          <Tooltip.Trigger asChild>
            <div>{InputField}</div>
          </Tooltip.Trigger>
          <Tooltip.Content side="bottom" align="end">
            {t('components.views.form.read_only_tooltip')}
          </Tooltip.Content>
        </Tooltip>
      ) : (
        InputField
      )}
      {input.instructions && <p className="text-xs text-subtle">{input.instructions}</p>}
    </FormInputWrapper>
  );
}
