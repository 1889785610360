import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { type DetailsViewOuterColumn } from '@/types/schema/views/DetailsView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { DetailsColumnEditor } from './DetailsColumnEditor';

function DroppableDetailsOuterColumn({
  outerColumn,
  record
}: {
  outerColumn: DetailsViewOuterColumn;
  record: FormattedViewRecord | undefined;
}) {
  const innerColumn = outerColumn.groups[0].columns[0];

  // This is needed so empty columns can be droppable
  const { setNodeRef, over } = useDroppable({
    id: outerColumn.id,
    data: {
      type: 'column',
      isEmpty: innerColumn.length === 0
    }
  });

  return (
    <SortableContext id={outerColumn.id} items={innerColumn}>
      <DetailsColumnEditor
        ref={setNodeRef}
        outerColumn={outerColumn}
        column={innerColumn}
        record={record}
        isDraggingOver={over?.id === outerColumn.id}
      />
    </SortableContext>
  );
}

export function DroppableDetailsOuterColumns({
  outerColumns,
  record,
  className
}: {
  outerColumns: DetailsViewOuterColumn[];
  record: FormattedViewRecord | undefined;
  className?: string;
}) {
  return (
    <div
      className={cn(
        'flex w-full flex-wrap gap-4 sm:flex-nowrap',
        {
          'max-w-[600px]': outerColumns.length === 1
        },
        className
      )}
    >
      {outerColumns.map((outerColumn) => (
        <div
          key={outerColumn.id}
          className={cn('w-full min-w-0 flex-1', {
            'basis-1/2': outerColumn.width === 50,
            'basis-1/3': outerColumn.width === 33,
            'basis-2/3': outerColumn.width === 66,
            'basis-1/4': outerColumn.width === 25,
            'basis-3/4': outerColumn.width === 75
          })}
        >
          <DroppableDetailsOuterColumn
            key={outerColumn.id}
            outerColumn={outerColumn}
            record={record}
          />
        </div>
      ))}
    </div>
  );
}
