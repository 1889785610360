import { createContext, useContext, type ReactNode } from 'react';
import { Banner } from '@knack/asterisk-react';

import { type Session } from '@/types/session';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';

const SessionContext = createContext<Session | null>(null);

export const useSessionContext = () => {
  const session = useContext(SessionContext);
  return session;
};

export function SessionContextProvider({ children }: { children: ReactNode }) {
  const { isLoading, isError, error, data } = useSessionQuery();

  if (isLoading) {
    return null;
  }

  if (isError) {
    return (
      <Banner intent="destructive">
        <Banner.Message>{error.message}</Banner.Message>
      </Banner>
    );
  }

  return <SessionContext.Provider value={data ?? null}>{children}</SessionContext.Provider>;
}
