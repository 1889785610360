import { Controller, useFormContext } from 'react-hook-form';
import { Combobox } from '@knack/asterisk-react';
import { t } from 'i18next';

import { type MultipleChoiceField } from '@/types/schema/fields';
import { type ViewFilters } from '@/hooks/useViewFilters';

interface FilterMultipleChoiceInputProps {
  field: MultipleChoiceField;
  criteriaIndex: number;
}

export function FilterMultipleChoiceInput({
  field,
  criteriaIndex
}: FilterMultipleChoiceInputProps) {
  const { control } = useFormContext<ViewFilters>();

  return (
    <Controller
      control={control}
      name={`rules.${criteriaIndex}.value`}
      render={({ field: { value, onChange } }) => {
        const options = field.format.options.map((option) => ({
          key: option,
          label: option
        }));

        let selectedOption = { key: '', label: '' };

        if (value === 'kn-blank') {
          selectedOption.key = 'kn-blank';
        } else if (typeof value === 'string') {
          selectedOption = { key: value, label: value };
        }

        return (
          <Combobox
            id={`view-filters-${criteriaIndex}-multiple-choice-input`}
            triggerClassName="h-9"
            options={options}
            selectedOption={selectedOption}
            addOptionText={t('actions.add')}
            onSelectOption={(option) => onChange(option.key)}
          />
        );
      }}
    />
  );
}
