import { useQuery } from '@tanstack/react-query';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { isInternalBuilderIframe } from '@/utils/iframe';

async function getPageRulesMatch(page: LiveAppPage, recordId: string | undefined) {
  // The `rules` object in the response is an array with the keys of the matching page rules
  const { data } = await axios.get<{ rules: string[] }>(
    `/v1/scenes/${page.key}/rules/match/${recordId || ''}`,
    {
      withCredentials: true
    }
  );

  return page.rules?.filter(({ key }) => data.rules.includes(key));
}

export function usePageRulesMatchQuery({
  page,
  recordId
}: {
  page: LiveAppPage;
  recordId: string | undefined;
}) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useQuery({
    queryKey: [queryKeys.page, page.key, recordId, accountSlug, appSlug],
    queryFn: async () => getPageRulesMatch(page, recordId),
    enabled: (page.rules && page.rules.length > 0 && !isInternalBuilderIframe()) || false
  });
}
