import { useFormContext } from 'react-hook-form';
import { Input } from '@knack/asterisk-react';

import { type FormViewShortTextInput } from '@/types/schema/views/form/ShortText';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';

export function ShortTextInput({
  input,
  isReadOnly
}: {
  input: FormViewShortTextInput;
  isReadOnly?: boolean;
}) {
  const { view } = useViewContext();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <Input
        disabled={isReadOnly}
        id={`${view.key}-${input.id}`}
        data-testid={`${view.key}-${input.id}-short-text-input`}
        intent={errors[input.field.key] ? 'destructive' : 'default'}
        {...register(input.field.key)}
      />
      <FormErrorMessage errors={errors} name={input.field.key} />
    </>
  );
}
