import { useTranslation } from 'react-i18next';
import { SimplePagination } from '@knack/asterisk-react';

import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type TableView } from '@/types/schema/views/TableView';
import { useViewSearchParams } from '@/hooks/useViewSearchParams';
import { RowsPerPageSelect } from '@/components/views/view-options/RowsPerPageSelect';
import { useViewContext } from '@/components/views/ViewContext';
import { RecordsRange } from './RecordsRange';

type LargeViewFooterProps = {
  shouldAllowChangeRowsPerPage: boolean;
  shouldRenderPagination: boolean;
  totalPageNumber: number;
  totalRecords?: number;
};

export function LargeViewFooter({
  shouldAllowChangeRowsPerPage,
  shouldRenderPagination,
  totalPageNumber,
  totalRecords
}: LargeViewFooterProps) {
  const [t] = useTranslation();
  const { view } = useViewContext<MapView | ListView | TableView>();
  const { currentPage, rowsPerPage, setViewParam } = useViewSearchParams(view);

  return (
    <>
      {shouldAllowChangeRowsPerPage && (
        <div className="flex items-center gap-2">
          <RowsPerPageSelect />
          {totalRecords && rowsPerPage && (
            <RecordsRange
              totalRecords={totalRecords}
              rowsPerPage={Number(rowsPerPage)}
              currentPage={Number(currentPage)}
            />
          )}
        </div>
      )}
      {!shouldAllowChangeRowsPerPage && rowsPerPage && (
        <div className="text-xs text-subtle">{`${t('components.views.showing')} ${rowsPerPage} ${t('components.views.per_page')}`}</div>
      )}
      {shouldRenderPagination && (
        <div data-testid="pagination">
          <SimplePagination
            totalPages={totalPageNumber}
            currentPage={Number(currentPage)}
            onPageChange={(page) => setViewParam({ page })}
            maxVisiblePages={3}
          />
        </div>
      )}
    </>
  );
}
