import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { usePageSegmentsHelpers } from '@/hooks/helpers/usePageSegmentsHelpers';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { usePageContext } from '@/context/PageContext';

interface AddOptionParams {
  pageKey: string;
  viewKey: string;
  pageUrl?: string;
  parentPageUrl?: string;
  data: any;
}

type RecordMutationResponse = {
  record: ViewRecord;
  submit_key: string | boolean;
};

export async function addRecord({
  pageKey,
  viewKey,
  pageUrl,
  parentPageUrl,
  data
}: AddOptionParams) {
  const url = `/v1/scenes/${pageKey}/views/${viewKey}/records/`;

  const { data: responseData } = await axios.post<RecordMutationResponse>(
    url,
    {
      // These two properties are used when processing email rules, since the rules might use template variables that refer to the page and parent page.
      parent_url: parentPageUrl,
      url: pageUrl,

      ...data
    },
    {
      withCredentials: true
    }
  );

  return responseData;
}

export function useAddRecordMutation() {
  const queryClient = useQueryClient();

  const { activePage } = usePageContext();
  const { getPreviousPageFullUrl } = usePageSegmentsHelpers();

  if (!activePage) {
    throw new Error('Active page not found');
  }

  return useMutation({
    mutationFn: ({ viewKey, data }: { viewKey: string; data: any }) =>
      addRecord({
        pageKey: activePage.key,
        viewKey,
        pageUrl: window.location.href,
        parentPageUrl: getPreviousPageFullUrl(),
        data
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.viewData]
      });
    }
  });
}
