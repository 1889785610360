import { useRef, type ComponentPropsWithoutRef } from 'react';
import { Chart } from '@knack/asterisk-react';
import {
  Area,
  AreaChart,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from 'recharts';
import { type HorizontalAlignmentType } from 'recharts/types/component/DefaultLegendContent';

import type { ChartData, ReportViewChart } from '@/types/schema/views/ReportView';
import { getDefaultChartLayout } from '@/utils/schema/patch/reports';
import { ChartDataTable } from './shared/ChartDataTable';
import { ChartEmptyState } from './shared/ChartEmptyState';
import { ChartExportButton } from './shared/ChartExportButton';
import { ChartHeader } from './shared/ChartHeader';
import { useChart } from './shared/useChart';
import { useChartHelpers } from './shared/useChartHelpers';

interface AreaChartRenderProps extends ComponentPropsWithoutRef<'div'> {
  chart: ReportViewChart;
  chartData: ChartData;
}

export function AreaChartRender({ chart, chartData, ...rest }: AreaChartRenderProps) {
  const { getRecordsInPercentage } = useChartHelpers();
  const {
    chartRecords,
    chartCalculationsFormat,
    chartKeys,
    chartConfig,
    hasMultipleGroups,
    maxCalculationChartKey
  } = useChart({ chart, chartData });

  const chartRef = useRef<HTMLDivElement>(null);

  if (
    !chartData ||
    !chartData.records ||
    chartData.records.length === 0 ||
    chartRecords.length === 0
  ) {
    return <ChartEmptyState />;
  }

  const chartLayout = chart.layout || getDefaultChartLayout();
  const shouldUseVerticalLegend = chartLayout.legend === 'right' || chartLayout.legend === 'left';
  const shouldStackCalculations =
    chartLayout.stacking === 'normal' || chartLayout.stacking === 'percent';
  const hasPercentStacking = chartLayout.stacking === 'percent';
  const shouldTiltLabels = chartLayout.tilt_labels;
  const { shouldShowDataTable, export_links: shouldShowExportButton } = chart.options;

  return (
    <div className="kn-area-chart" data-testid="area-chart" {...rest}>
      <ChartHeader chart={chart} />
      {shouldShowExportButton && <ChartExportButton chart={chart} chartRef={chartRef} />}
      <Chart ref={chartRef} config={chartConfig} className="size-full">
        <AreaChart
          accessibilityLayer
          data={hasPercentStacking ? getRecordsInPercentage(chartRecords) : chartRecords}
          margin={{
            top: 15,
            bottom: 10,
            left: 10,
            right: 10
          }}
        >
          {!hasMultipleGroups && (
            <YAxis
              dataKey={shouldStackCalculations ? undefined : maxCalculationChartKey}
              type="number"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value}
            />
          )}
          <XAxis
            dataKey="group_0"
            type="category"
            tickLine={false}
            axisLine={false}
            {...(shouldTiltLabels ? { angle: -45, textAnchor: 'end' } : {})}
          />
          <RechartsTooltip cursor={false} content={<Chart.TooltipContent hideLabel />} />
          {chartLayout.legend !== 'none' && (
            <RechartsLegend
              content={<Chart.LegendContent />}
              layout={shouldUseVerticalLegend ? 'vertical' : 'horizontal'}
              verticalAlign={shouldUseVerticalLegend ? 'middle' : undefined}
              align={
                chartLayout.legend === 'bottom'
                  ? 'center'
                  : (chartLayout.legend as HorizontalAlignmentType)
              }
            />
          )}
          {Object.keys(chartKeys).map((key) => (
            <Area
              key={`${chart.id}-bar-${key}`}
              dataKey={key}
              fill={`var(--color-${key})`}
              stroke={`var(--color-${key})`}
              fillOpacity={0.4}
              label={
                chartLayout.data_labels
                  ? {
                      position: 'top',
                      formatter: (value: number) => `${chartCalculationsFormat}${value}`
                    }
                  : false
              }
              type={chartLayout.line_type}
              dot
              stackId={shouldStackCalculations ? '1' : undefined}
            />
          ))}
        </AreaChart>
      </Chart>
      {shouldShowDataTable && <ChartDataTable chart={chart} chartRecords={chartRecords} />}
    </div>
  );
}
