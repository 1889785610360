import { createContext, useContext, useMemo } from 'react';
import { Spinner } from '@knack/asterisk-react';

import { type KnackObjectResponse } from '@/types/schema/KnackObject';
import { type LiveAppView, type ViewSource } from '@/types/schema/LiveAppView';
import { useTableQuery } from '@/hooks/api/queries/useTableQuery';

type ViewContextState<T extends LiveAppView> = {
  view: T;

  // If the view has a `source` property, the `sourceTable` will be of type KnackObjectResponse. Otherwise, it will be null
  sourceTable: T extends { source: ViewSource } ? KnackObjectResponse : null;
};

type ViewContextProviderProps<T extends LiveAppView> = {
  view: T;
  children: React.ReactNode;

  // Optional prop to provide a demo source table instead of fetching it from the API
  demoSourceTable?: KnackObjectResponse;
};

const ViewContext = createContext<ViewContextState<LiveAppView> | null>(null);

export function ViewContextProvider<T extends LiveAppView>({
  view,
  demoSourceTable,
  children
}: ViewContextProviderProps<T>) {
  const sourceTableKey = 'source' in view && view.source?.object ? view.source.object : undefined;

  const { data: sourceTable, isLoading } = useTableQuery({
    tableKey: sourceTableKey,
    isEnabled: !demoSourceTable
  });

  const contextValue = useMemo(
    () => ({ view, sourceTable: demoSourceTable || (sourceTable ?? null) }),
    [view, demoSourceTable, sourceTable]
  );

  if (isLoading) {
    return <Spinner />;
  }

  return <ViewContext.Provider value={contextValue}>{children}</ViewContext.Provider>;
}

export const useViewContext = <T extends LiveAppView>() => {
  const context = useContext(ViewContext);

  if (!context) {
    throw new Error('useViewContext must be used within an ViewContextProvider');
  }

  return context as ViewContextState<T>;
};
