import { Outlet } from 'react-router-dom';
import { isAxiosError } from 'axios';

import { BuilderSetup } from './BuilderSetup';
import { AppInMaintenance } from './components/errors/AppInMaintenance';
import { APP_LOAD_ERROR_MESSAGE } from './components/errors/ErrorFallback';
import { FullPageSpinner } from './components/FullPageSpinner';
import { InactivityTimeout } from './components/InactivityTimeout';
import { MessagingContextProvider } from './context/MessagingContext';
import { PageContextProvider } from './context/PageContext';
import { SessionContextProvider } from './context/SessionContext';
import { ThemingContextProvider } from './context/ThemingContext';
import { FlagsmithSetup } from './FlagsmithSetup';
import { useAppInit } from './hooks/useAppInit';
import { LiveAppSetup } from './LiveAppSetup';
import { ThirdPartyIntegrationsSetup } from './ThirdPartyIntegrationsSetup';
import { isInternalBuilderIframe } from './utils/iframe';

const isAppInMaintenanceError = (error: unknown): boolean => {
  if (!isAxiosError(error)) {
    return false;
  }
  return error.status === 400 && error.response?.data?.errorCode === 'app_maintenance';
};

export function App() {
  const { application, account, isPending, isError, errors } = useAppInit();

  if (isPending) {
    return <FullPageSpinner />;
  }

  if (isError && errors.some(isAppInMaintenanceError)) {
    return <AppInMaintenance />;
  }

  if (!application || !account) {
    window.location.replace(import.meta.env.PUBLIC_KNACK_REDIRECT_URL);
    return null;
  }

  if (isError) {
    throw new Error(APP_LOAD_ERROR_MESSAGE);
  }

  if (isInternalBuilderIframe()) {
    return (
      <ThemingContextProvider theming={application.theming}>
        <BuilderSetup>
          <MessagingContextProvider>
            <SessionContextProvider>
              <PageContextProvider pages={application.pages} homePage={application.homePage}>
                <Outlet />
              </PageContextProvider>
            </SessionContextProvider>
          </MessagingContextProvider>
        </BuilderSetup>
      </ThemingContextProvider>
    );
  }

  return (
    <FlagsmithSetup accountId={account.id}>
      <ThemingContextProvider theming={application.theming}>
        <LiveAppSetup>
          <SessionContextProvider>
            <PageContextProvider pages={application.pages} homePage={application.homePage}>
              <ThirdPartyIntegrationsSetup>
                <Outlet />
                <InactivityTimeout />
              </ThirdPartyIntegrationsSetup>
            </PageContextProvider>
          </SessionContextProvider>
        </LiveAppSetup>
      </ThemingContextProvider>
    </FlagsmithSetup>
  );
}
