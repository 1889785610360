import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObjectResponse } from '@/types/schema/KnackObject';
import { type ListView } from '@/types/schema/views/ListView';
import { ViewFiltersPopover } from '@/components/views/view-options/ViewFiltersPopover';

interface ListViewFiltersPopoverProps {
  view: ListView;
  sourceObject: KnackObjectResponse;
}

export function ListViewFiltersPopover({ view, sourceObject }: ListViewFiltersPopoverProps) {
  let eligibleCriteriaFields: KnackField[] = [];

  if (view.filter_fields === 'view') {
    view.columns.forEach((outerColumn) => {
      outerColumn.groups.forEach((group) => {
        group.columns.forEach((innerColumn) => {
          innerColumn.forEach((input) => {
            if (input.type === 'field' && !input.connection) {
              const field = sourceObject.fields.find((f) => f.key === input.key);
              if (field) {
                eligibleCriteriaFields.push(field);
              }
            }
          });
        });
      });
    });
  }

  if (view.filter_fields === 'object') {
    eligibleCriteriaFields = sourceObject.fields;
  }

  return <ViewFiltersPopover view={view} eligibleCriteriaFields={eligibleCriteriaFields} />;
}
