import { type CSSProperties } from 'react';
import DOMPurify from 'dompurify';

import { type DetailsView, type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { useDetailsDisplayRules } from '@/components/views/details/useDetailsDisplayRules';
import { Map } from '@/components/views/map/engine/Map';
import { useViewContext } from '@/components/views/ViewContext';
import { DetailsInputValue } from './DetailsInputValue';
import { getLabelStyles, getValueStyles } from './helper';

interface DetailsFieldInputProps {
  input: DetailsViewInput;
  record: FormattedViewRecord | undefined;
  isLastInGroup?: boolean;
}

export function DetailsFieldInput({ input, record, isLastInGroup }: DetailsFieldInputProps) {
  const { view, sourceTable } = useViewContext<ListView | DetailsView | MapView>();

  const { processDisplayRuleActions } = useDetailsDisplayRules(
    record ? record.values : undefined,
    view.type !== 'map' ? view.rules?.fields : undefined
  );

  const sanitizedValue = DOMPurify.sanitize(record?.values[input.key]);
  const shouldHideFields = view.type !== 'map' ? view.hide_fields : false;
  const processedDisplayRuleActions = processDisplayRuleActions(input.key);

  if (processedDisplayRuleActions.shouldHideField) {
    return null;
  }

  if (!record?.values[input.key] && shouldHideFields) {
    return null;
  }

  const field = sourceTable.fields.find((f) => f.key === input.key);

  const inputDisplayRulesStyle: CSSProperties = {
    backgroundColor: processedDisplayRuleActions.bgColor || undefined,
    color: processedDisplayRuleActions.textColor || undefined
  };

  const isLabelOnTop =
    (view.type !== 'map' && view.label_format === 'top') || input.format?.label_format === 'top';
  const shouldRenderLabel =
    (view.type === 'map' && input.format?.label_custom && input.format?.label_format !== 'none') ||
    (view.type !== 'map' && view.label_format !== 'none' && input.format?.label_format !== 'none');

  const inputLabelClasses = cn(
    'bg-muted p-2 font-medium',
    {
      'w-full': isLabelOnTop,
      'w-1/4 min-w-32 max-w-52': !isLabelOnTop,
      'border-b border-default': isLabelOnTop || !isLastInGroup
    },
    getLabelStyles(input, view, processedDisplayRuleActions)
  );
  const inputValueClasses = cn(
    'flex-1 break-normal p-2',
    {
      'w-full': isLabelOnTop,
      'min-h-8': isLabelOnTop || !shouldRenderLabel,
      'border-b border-default': !isLastInGroup
    },
    getValueStyles(input, processedDisplayRuleActions)
  );

  return (
    <div
      className={cn('flex', {
        'flex-wrap': isLabelOnTop
      })}
      key={input.id}
    >
      {shouldRenderLabel && (
        <span className={inputLabelClasses} style={inputDisplayRulesStyle}>
          {processedDisplayRuleActions.label || input.label || input.name}
        </span>
      )}
      {view.type !== 'map' && input.show_map ? (
        <div className={inputValueClasses}>
          <Map
            formattedViewRecords={record ? [record] : []}
            mapHeight={input.map_height}
            addressFieldKey={input.key}
          />
          <div
            className="mt-2"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: sanitizedValue }}
          />
        </div>
      ) : (
        <DetailsInputValue
          className={inputValueClasses}
          value={sanitizedValue}
          field={field}
          style={inputDisplayRulesStyle}
        />
      )}
    </div>
  );
}
