import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObjectResponse } from '@/types/schema/KnackObject';
import { type TableView } from '@/types/schema/views/TableView';
import { ViewFiltersPopover } from '@/components/views/view-options/ViewFiltersPopover';

interface TableViewFiltersPopoverProps {
  view: TableView;
  sourceObject: KnackObjectResponse;
}

export function TableViewFiltersPopover({ view, sourceObject }: TableViewFiltersPopoverProps) {
  let eligibleCriteriaFields: KnackField[] = [];

  if (view.filter_fields === 'view') {
    view.columns.forEach((column) => {
      if (column.type === 'field' && !column.connection) {
        const field = sourceObject.fields.find((f) => f.key === column.field?.key);
        if (field) {
          eligibleCriteriaFields.push(field);
        }
      }
    });
  }

  if (view.filter_fields === 'object') {
    eligibleCriteriaFields = sourceObject.fields;
  }

  return <ViewFiltersPopover view={view} eligibleCriteriaFields={eligibleCriteriaFields} />;
}
