import { Helmet } from 'react-helmet-async';

import { useAppInit } from './hooks/useAppInit';

export function AppCustomCode() {
  const { application } = useAppInit();
  const path = `https://${import.meta.env.PUBLIC_S3_APP_CDN_DOMAIN}/${application?.id}/custom`;

  return (
    <Helmet>
      <link href={`${path}/v4/main.css`} rel="stylesheet" />
      <script src={`${path}/v4/main.js`} defer />
    </Helmet>
  );
}
