import { type ComponentProps } from 'react';
import { Rating, RichTextRenderer } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';

interface DetailsInputValueProps extends ComponentProps<'div'> {
  field: KnackField | undefined;
  value: string;
}

export function DetailsInputValue({ field, value, ...rest }: DetailsInputValueProps) {
  if (field?.type === 'rating') {
    return (
      <div {...rest}>
        <Rating
          ratingNumber={Number(value)}
          maxRatingNumber={parseInt(field?.format.max, 10)}
          allowHalf={field?.format.allow_half}
        />
      </div>
    );
  }

  return <RichTextRenderer {...rest} dangerouslySetInnerHTML={{ __html: value }} />;
}
