import { forwardRef } from 'react';

import {
  type DetailsViewColumn,
  type DetailsViewInput,
  type DetailsViewOuterColumn
} from '@/types/schema/views/DetailsView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { DragInsertIndicator } from '@/pages/page/page-editor/page-editor-sortable/DragInsertIndicator';
import { useThemingContext } from '@/context/ThemingContext';
import { SortableDetailsInput } from './SortableDetailsInput';

interface DetailsColumnEditorProps {
  outerColumn: DetailsViewOuterColumn;
  column: DetailsViewColumn;
  record: FormattedViewRecord | undefined;
  isDraggingOver: boolean;
}

function DetailsColumnEditorWithRef(
  { outerColumn, column, record, isDraggingOver }: DetailsColumnEditorProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { theme } = useThemingContext();

  // We want to keep track of the inputs that have been rendered so we don't render them again, as multiple field inputs can be grouped together
  const renderedInputIds: string[] = [];
  const isColumnEmpty = column.length === 0;

  return (
    <div ref={ref} className="h-full">
      <DragInsertIndicator isVisible={isDraggingOver && isColumnEmpty} />

      <div
        className={cn('max-w-full flex-1 space-y-2', {
          'h-full': isColumnEmpty
        })}
      >
        {isColumnEmpty && !isDraggingOver ? (
          <div
            aria-hidden="true"
            className="relative flex h-full items-center justify-center rounded-md border-2 border-dashed border-subtle p-4 hover:border-default"
          />
        ) : (
          column.map((input, inputIndex) => {
            if (renderedInputIds.includes(input.id)) {
              return null;
            }

            renderedInputIds.push(input.id);

            if (input.type !== 'field') {
              return (
                <SortableDetailsInput
                  key={input.id}
                  input={input}
                  outerColumn={outerColumn}
                  record={record}
                />
              );
            }

            // Find the inputs after this one that are of type 'field', until the next non-field input
            const nextPartOfArray = column.slice(inputIndex);
            const fieldInputs: DetailsViewInput[] = [];
            for (let i = 0; i < nextPartOfArray.length; i += 1) {
              const nextInput = nextPartOfArray[i];
              if (nextInput.type !== 'field') {
                break;
              }
              fieldInputs.push(nextInput);
            }

            if (fieldInputs.length === 0) {
              return null;
            }

            return (
              <div
                key={`input-field-group-starting-at-${input.id}`}
                className={cn('overflow-x-auto border border-default bg-card text-sm', {
                  'rounded-lg': theme.appearance.corners === 'rounded'
                })}
              >
                {fieldInputs.map((fieldInput, fieldInputIndex) => {
                  renderedInputIds.push(fieldInput.id);

                  return (
                    <SortableDetailsInput
                      key={fieldInput.id}
                      input={fieldInput}
                      outerColumn={outerColumn}
                      isLastInGroup={fieldInputIndex === fieldInputs.length - 1}
                      record={record}
                    />
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export const DetailsColumnEditor = forwardRef<HTMLDivElement, DetailsColumnEditorProps>(
  DetailsColumnEditorWithRef
);
