import { type LiveAppPageFull } from '@/types/schema/LiveAppPage';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { View } from '@/components/views/View';
import { useAuthFlow } from './AuthFlowContext';
import { PageColumn } from './PageColumn';
import { PageSection } from './PageSection';

interface PageSectionsProps {
  page: LiveAppPageFull;
  viewKeysToHide?: LiveAppViewKey[];
}

export function PageSections({ page, viewKeysToHide }: PageSectionsProps) {
  const { activeAuthFlow, activeSignupViewKey } = useAuthFlow();

  const viewsMap = new Map(page.views.map((view) => [view.key, view]));

  // If the page is an authentication page and the active auth view is 'signup', we only want to render the signup view and nothing else
  if (page.type === 'authentication' && activeAuthFlow === 'signup' && activeSignupViewKey) {
    const signupView = viewsMap.get(activeSignupViewKey);

    if (!signupView || signupView.type !== 'registration') {
      return null;
    }

    return <View view={signupView} />;
  }

  return (
    <>
      {/* If the page's parents have menu views that link to this page (e.g. tabs menus), we need to render those menu views at the top of the page  */}
      {page.parentLinkedMenuViews?.map((view) => <View key={view.key} view={view} />)}

      {page.groups.map((section) => {
        // Skip rendering the section if all columns are empty
        if (
          section.columns.every(
            (column) =>
              column.viewKeys.filter((viewKey) => !viewKeysToHide?.includes(viewKey)).length === 0
          )
        ) {
          return null;
        }

        return (
          <PageSection key={section.id}>
            {section.columns.map((column) => (
              <PageColumn key={column.id} column={column}>
                {column.viewKeys.map((viewKey) => {
                  if (viewKeysToHide?.includes(viewKey)) {
                    return null;
                  }

                  const view = viewsMap.get(viewKey);

                  // Skip rendering the view if it's a registration view.
                  // Registration views shouldn't be in the array of viewKeys for a column, but pages with corrupted schemas can still have them.
                  if (!view || view.type === 'registration') {
                    return null;
                  }

                  return <View key={viewKey} view={view} />;
                })}
              </PageColumn>
            ))}
          </PageSection>
        );
      })}
    </>
  );
}
