import { DateTime } from 'luxon';

import {
  type KnackCriteria,
  type KnackCriteriaDateTimeValue,
  type KnackCriteriaLinkValue
} from '@/types/schema/KnackCriteria';
import { type KnackField } from '@/types/schema/KnackField';
import { getBooleanValue } from '@/components/views/form/inputs/boolean/helper';
import { defaultDateFormatMap } from '@/components/views/form/inputs/date-time/types';

function getDateCriteriaRangeTypeFormatted(
  criteriaDateRangeType: KnackCriteria['type'] = 'days'
): 'week' | 'day' | 'month' | 'quarter' | 'year' {
  switch (criteriaDateRangeType) {
    case 'rolling months':
    case 'months':
    case 'month':
      return 'month';

    case 'rolling weeks':
    case 'weeks':
    case 'week':
      return 'week';

    case 'rolling years':
    case 'years':
    case 'year':
      return 'year';

    case 'quarter':
      return 'quarter';

    default:
      return 'day';
  }
}

function getFormFieldValue(fieldValue: KnackCriteria['value'], field: KnackField) {
  if (typeof fieldValue === 'boolean') {
    return fieldValue as boolean;
  }

  if (typeof fieldValue === 'number') {
    return fieldValue as number;
  }

  if (typeof fieldValue === 'string') {
    if (field.type === 'boolean') {
      return getBooleanValue(fieldValue, field.format);
    }

    return fieldValue.toLowerCase();
  }

  if (field.type === 'link') {
    return (fieldValue as KnackCriteriaLinkValue).url.toLowerCase();
  }

  if (Array.isArray(fieldValue) && field.type === 'multiple_choice') {
    // When we have a Multiple choice field using a multi select, it is possible to receive an array of values
    const fieldValueArrayToLowerCase = (fieldValue as string[]).map((v: string) => v.toLowerCase());
    // When we compare an array of values, for operators like "is", "is not", that refer to a single value,
    // we must take the first value of the array to make the comparison and only when the array has a single value
    if (fieldValueArrayToLowerCase.length === 1) {
      return fieldValueArrayToLowerCase[0];
    }
    return fieldValueArrayToLowerCase;
  }

  if (Array.isArray(fieldValue) && field.type === 'connection') {
    if (fieldValue.length === 0) return [];
    return fieldValue[0];
  }

  return null;
}

export function isCriteriaMet(
  rawFieldValue: KnackCriteria['value'],
  field: KnackField,
  criteria: KnackCriteria
): boolean {
  const { operator, value: rawCriteriaValue, type = 'days', range = 1 } = criteria;

  if (field.type === 'date_time') {
    const criteriaValue = rawCriteriaValue as KnackCriteriaDateTimeValue;
    const fieldValue = rawFieldValue as KnackCriteriaDateTimeValue;
    const formattedDurationType = getDateCriteriaRangeTypeFormatted(type);
    const OPERATORS_TIME_ONLY = ['is before current time', 'is after current time'];
    const OPERATORS_RANGE = [
      'is during the current',
      'is during the previous',
      'is during the next',
      'is before the previous',
      'is after the next'
    ];
    const OPERATORS_WITHOUT_DATE = [
      'is today',
      'is today or before',
      'is today or after',
      'is before today',
      'is after today',
      'is blank',
      'is not blank'
    ];
    const today = DateTime.now().set({ second: 0, millisecond: 0 });
    const hasCriteriaTime = !!criteriaValue?.time;
    const hasCriteriaDate = !!criteriaValue?.date;
    const isOperatorWithoutDate = OPERATORS_WITHOUT_DATE.includes(operator);
    const isRangeOperator = OPERATORS_RANGE.includes(operator);
    const isTimeOnlyOperator =
      OPERATORS_TIME_ONLY.includes(operator) ||
      (!hasCriteriaDate && hasCriteriaTime && !isRangeOperator);
    const dateFormat = field.format ? defaultDateFormatMap[field.format.date_format] : 'MM/dd/yyyy';
    const timeFormat = field.format.time_format === 'HH:MM am' ? 'hh:mma' : 'HH:mm';

    // Create the dates objects without time, so time can be added later just if needed.
    let criteriaDateTime: DateTime | null = criteriaValue?.date
      ? DateTime.fromFormat(criteriaValue?.date, dateFormat).set({ second: 0, millisecond: 0 })
      : null;

    let fieldDateTime: DateTime | null = null;
    if (typeof fieldValue === 'string') {
      fieldDateTime = DateTime.fromFormat(fieldValue, `${dateFormat}`).set({
        second: 0,
        millisecond: 0
      });
    } else {
      fieldDateTime = fieldValue?.date
        ? DateTime.fromFormat(fieldValue?.date, dateFormat).set({ second: 0, millisecond: 0 })
        : null;
    }

    if (isTimeOnlyOperator) {
      // If the operator compares just time, set the dates to the same day so it doesn't affects operations.
      criteriaDateTime = DateTime.fromFormat(
        `${today.toFormat(dateFormat)} ${criteriaValue?.time}`,
        'MM/dd/yyyy h:mma'
      ).set({ second: 0, millisecond: 0 });

      if (typeof fieldValue === 'string') {
        fieldDateTime = today.set({
          hour: fieldDateTime?.hour,
          minute: fieldDateTime?.minute,
          second: fieldDateTime?.second,
          millisecond: fieldDateTime?.millisecond
        });
      } else {
        fieldDateTime = DateTime.fromFormat(
          `${today.toFormat(dateFormat)} ${fieldValue?.hours}:${fieldValue?.minutes}${fieldValue?.am_pm}`,
          'MM/dd/yyyy h:mma'
        ).set({ second: 0, millisecond: 0 });
      }
    }

    if (!isTimeOnlyOperator && hasCriteriaTime) {
      // If the criteria has time, field and criteria dates have to include it when applying the operators.
      criteriaDateTime = criteriaDateTime
        ? DateTime.fromFormat(
            `${criteriaDateTime.toFormat(dateFormat)} ${criteriaValue?.time}`,
            'MM/dd/yyyy h:mma'
          ).set({ second: 0, millisecond: 0 })
        : null;

      if (typeof fieldValue === 'string') {
        fieldDateTime = DateTime.fromFormat(fieldValue, `${dateFormat} ${timeFormat}`).set({
          second: 0,
          millisecond: 0
        });
      } else {
        fieldDateTime = fieldDateTime
          ? DateTime.fromFormat(
              `${fieldDateTime.toFormat(dateFormat)} ${fieldValue?.hours}:${fieldValue?.minutes}${fieldValue?.am_pm}`,
              'MM/dd/yyyy h:mma'
            ).set({ second: 0, millisecond: 0 })
          : null;
      }
    }

    if (
      !isOperatorWithoutDate &&
      !isTimeOnlyOperator &&
      !isRangeOperator &&
      !hasCriteriaDate &&
      !hasCriteriaTime
    ) {
      // If the criteria has no date and no time specified, and the operator is not date-only, time-only, or a range operator, the operator is treated as "is not blank".
      return fieldDateTime !== null;
    }

    switch (operator) {
      case 'is':
        return JSON.stringify(fieldDateTime) === JSON.stringify(criteriaDateTime);
      case 'is not':
        return JSON.stringify(fieldDateTime) !== JSON.stringify(criteriaDateTime);
      case 'is before':
        return !!fieldDateTime && !!criteriaDateTime && fieldDateTime < criteriaDateTime;
      case 'is after':
        return !!fieldDateTime && !!criteriaDateTime && fieldDateTime > criteriaDateTime;
      case 'is before current time':
        return !!fieldDateTime && fieldDateTime < today;
      case 'is after current time':
        return !!fieldDateTime && fieldDateTime > today;
      case 'is during the current':
        return !!fieldDateTime && fieldDateTime.hasSame(today, formattedDurationType);
      case 'is during the previous':
        if (type === 'weeks' || type === 'months' || type === 'years' || type === 'days') {
          const startPreviousPeriod = today.minus({ [type]: range });
          const endPreviousPeriod = startPreviousPeriod.plus({ [type]: range });
          return (
            !!fieldDateTime &&
            fieldDateTime >= startPreviousPeriod &&
            fieldDateTime <= endPreviousPeriod
          );
        }
        return (
          !!fieldDateTime &&
          fieldDateTime >= today.minus({ [formattedDurationType]: range }) &&
          fieldDateTime <= today
        );
      case 'is during the next':
        if (type === 'weeks' || type === 'months' || type === 'years' || type === 'days') {
          const startNextPeriod = today;
          const endNextPeriod = startNextPeriod.plus({ [type]: range });

          return (
            !!fieldDateTime && fieldDateTime >= startNextPeriod && fieldDateTime <= endNextPeriod
          );
        }
        return (
          !!fieldDateTime &&
          fieldDateTime >= today &&
          fieldDateTime <= today.plus({ [formattedDurationType]: range })
        );
      case 'is before the previous':
        if (type === 'weeks' || type === 'months' || type === 'years' || type === 'days') {
          const endPreviousPeriod = today.minus({ [type]: range });
          return !!fieldDateTime && fieldDateTime < endPreviousPeriod;
        }
        return !!fieldDateTime && fieldDateTime < today.minus({ [formattedDurationType]: range });
      case 'is after the next':
        if (type === 'weeks' || type === 'months' || type === 'years' || type === 'days') {
          const startNextPeriod = today.plus({ [type]: range });
          return !!fieldDateTime && fieldDateTime > startNextPeriod;
        }
        return !!fieldDateTime && fieldDateTime > today.plus({ [formattedDurationType]: range });
      case 'is today':
        return !!fieldDateTime && fieldDateTime.hasSame(today, 'day');
      case 'is today or before':
        return !!fieldDateTime && fieldDateTime <= today;
      case 'is today or after':
        return hasCriteriaTime
          ? !!fieldDateTime && fieldDateTime >= today
          : !!fieldDateTime && fieldDateTime >= today.startOf('day');
      case 'is before today':
        return hasCriteriaTime
          ? !!fieldDateTime && fieldDateTime < today
          : !!fieldDateTime && fieldDateTime < today.startOf('day');
      case 'is after today':
        return !!fieldDateTime && fieldDateTime > today;
      case 'is blank':
        return !fieldDateTime;
      case 'is not blank':
        return fieldDateTime !== null;
      default:
        return false;
    }
  } else {
    const formFieldValue = getFormFieldValue(rawFieldValue, field);

    if (formFieldValue === null) return false;

    if (typeof formFieldValue === 'boolean' && typeof rawCriteriaValue === 'boolean') {
      switch (operator) {
        case 'is':
          return formFieldValue === rawCriteriaValue;
        case 'is not':
          return formFieldValue !== rawCriteriaValue;
        // The following cases may not be necessary because we always receive a value, but they are here to ensure that the behavior is consistent
        case 'is blank':
          return formFieldValue === null;
        case 'is not blank':
          return formFieldValue !== null;
        default:
          return false;
      }
    }

    switch (operator) {
      case 'contains': {
        if (
          (Array.isArray(formFieldValue) || typeof formFieldValue === 'string') &&
          typeof rawCriteriaValue === 'string'
        ) {
          return formFieldValue.includes(rawCriteriaValue.toLowerCase());
        }
        break;
      }
      case 'does not contain': {
        if (
          (Array.isArray(formFieldValue) || typeof formFieldValue === 'string') &&
          typeof rawCriteriaValue === 'string'
        ) {
          return !formFieldValue.includes(rawCriteriaValue.toLowerCase());
        }
        break;
      }
      case 'is': {
        if (
          (typeof formFieldValue === 'string' || typeof formFieldValue === 'number') &&
          (typeof rawCriteriaValue === 'string' ||
            typeof rawCriteriaValue === 'number' ||
            Array.isArray(rawCriteriaValue))
        ) {
          if (Array.isArray(rawCriteriaValue) && field.type === 'connection') {
            return formFieldValue.toString() === rawCriteriaValue[0].toString().toLowerCase();
          }
          return formFieldValue.toString() === rawCriteriaValue.toString().toLowerCase();
        }
        break;
      }
      case 'is not': {
        if (
          (typeof formFieldValue === 'string' || typeof formFieldValue === 'number') &&
          (typeof rawCriteriaValue === 'string' || typeof rawCriteriaValue === 'number')
        ) {
          return formFieldValue.toString() !== rawCriteriaValue.toString().toLowerCase();
        }
        break;
      }
      case 'starts with': {
        if (typeof formFieldValue === 'string' && typeof rawCriteriaValue === 'string') {
          return formFieldValue.startsWith(rawCriteriaValue);
        }
        break;
      }
      case 'ends with': {
        if (typeof formFieldValue === 'string' && typeof rawCriteriaValue === 'string') {
          return formFieldValue.endsWith(rawCriteriaValue);
        }
        break;
      }
      case 'is blank': {
        if (Array.isArray(formFieldValue)) {
          return formFieldValue.length === 0; // Multiple choice field with blank option
        }
        if (typeof formFieldValue === 'string') {
          return formFieldValue === 'kn-blank' || formFieldValue === '';
        }
        break;
      }
      case 'is not blank': {
        if (Array.isArray(formFieldValue)) {
          return formFieldValue.length > 0;
        }
        if (typeof formFieldValue === 'string') {
          return formFieldValue !== 'kn-blank' && formFieldValue !== '';
        }
        break;
      }
      case 'higher than': {
        let formFieldValueNumber: number | null = null;
        let criteriaValueNumber: number | null = null;

        if (typeof formFieldValue === 'string') {
          formFieldValueNumber = parseInt(formFieldValue, 10);
        } else if (typeof formFieldValue === 'number') {
          formFieldValueNumber = formFieldValue;
        }

        if (typeof rawCriteriaValue === 'string') {
          criteriaValueNumber = parseInt(rawCriteriaValue, 10);
        } else if (typeof rawCriteriaValue === 'number') {
          criteriaValueNumber = rawCriteriaValue;
        }

        if (formFieldValueNumber === null || criteriaValueNumber === null) {
          break;
        }

        return formFieldValueNumber > criteriaValueNumber;
      }
      case 'lower than': {
        let formFieldValueNumber: number | null = null;
        let criteriaValueNumber: number | null = null;

        if (typeof formFieldValue === 'string') {
          formFieldValueNumber = parseInt(formFieldValue, 10);
        } else if (typeof formFieldValue === 'number') {
          formFieldValueNumber = formFieldValue;
        }

        if (typeof rawCriteriaValue === 'string') {
          criteriaValueNumber = parseInt(rawCriteriaValue, 10);
        } else if (typeof rawCriteriaValue === 'number') {
          criteriaValueNumber = rawCriteriaValue;
        }

        if (formFieldValueNumber === null || criteriaValueNumber === null) {
          break;
        }

        return formFieldValueNumber < criteriaValueNumber;
      }
      default:
        return false;
    }

    return false;
  }
}

function criteriaPredicate(
  data: { [key: string]: any },
  fields: KnackField[],
  criteriaRule: KnackCriteria,
  onCriteriaMet?: ({
    data,
    field,
    criteria
  }: {
    data: { [key: string]: any };
    field: KnackField;
    criteria: KnackCriteria;
  }) => void
): boolean {
  const { field: criteriaField } = criteriaRule;
  const field = fields.find((f) => f.key === criteriaField);
  if (!field) return false;

  const fieldValue = data[criteriaField];
  if (fieldValue === undefined) return false;

  if (field.type === 'connection' && Array.isArray(fieldValue) && fieldValue.length > 0) {
    const isMet = isCriteriaMet([fieldValue[0].id], field, criteriaRule);
    if (isMet && onCriteriaMet) {
      onCriteriaMet({
        data,
        field,
        criteria: criteriaRule
      });
    }
    return isMet;
  }

  const isMet = isCriteriaMet(fieldValue, field, criteriaRule);
  if (isMet && onCriteriaMet) {
    onCriteriaMet({
      data,
      field,
      criteria: criteriaRule
    });
  }
  return isMet;
}

type OnCriteriaMet<T> = ({
  data,
  field,
  criteria
}: {
  data: { [key: string]: any };
  field: KnackField;
  criteria: T;
}) => void;

export function isEveryCriteriaMet(
  data: { [key: string]: any },
  fields: KnackField[],
  criteria?: KnackCriteria[],
  onCriteriaMet?: OnCriteriaMet<KnackCriteria>
) {
  if (!criteria || criteria.length === 0) return false;
  return criteria.every((criteriaRule) =>
    criteriaPredicate(data, fields, criteriaRule, onCriteriaMet)
  );
}

export function isSomeCriteriaMet(
  data: { [key: string]: any },
  fields: KnackField[],
  criteria?: KnackCriteria[],
  onCriteriaMet?: OnCriteriaMet<KnackCriteria>
) {
  if (!criteria || criteria.length === 0) return false;
  return criteria.some((criteriaRule) =>
    criteriaPredicate(data, fields, criteriaRule, onCriteriaMet)
  );
}
