import { useRef, useState } from 'react';
import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiTrash as TrashIcon, HiArrowUpOnSquare as UploadIcon } from 'react-icons/hi2';
import { Button, Input, Spinner } from '@knack/asterisk-react';

import { type FormViewFileInput } from '@/types/schema/views/form/File';
import { type FormViewImageInput } from '@/types/schema/views/form/Image';
import { type FormView } from '@/types/schema/views/FormView';
import { useUploadAssetMutation } from '@/hooks/api/mutations/useUploadAssetMutation';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';

const maxFileSizeInMb = 250;
const maxFileSizeInBytes = maxFileSizeInMb * 1000 * 1000;
const imageAllowedFileFormats = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/svg+xml': ['.svg'],
  'image/webp': ['.webp']
};
const imageAllowedFormatsString = Object.keys(imageAllowedFileFormats).join(',');

export function FileInput({
  input,
  isReadOnly
}: {
  input: FormViewFileInput | FormViewImageInput;
  isReadOnly?: boolean;
}) {
  const [t] = useTranslation();
  const { mutate, isPending } = useUploadAssetMutation();
  const {
    register,
    setValue: setFormValue,
    formState: { errors },
    setError,
    getValues
  } = useFormContext();
  const { view } = useViewContext<FormView>();
  const { theme } = useThemingContext();
  const inputValue = getValues(input.field.key);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const isImageInput = input.type === 'image';
  const isExternalImageSource = isImageInput && input.format.source === 'url';

  const [fileName, setFileName] = useState(inputValue?.filename || '');
  const [thumbUrl, setThumbUrl] = useState(inputValue?.thumb_url || '');

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];

    if (file.size > maxFileSizeInBytes) {
      setError(input.field.key, {
        type: 'banner',
        message: t('components.views.form.file_input.file_exceed_limit', {
          limit: maxFileSizeInMb
        })
      });
      return;
    }

    mutate(
      {
        fieldKey: input.field.key,
        viewKey: view.key,
        file,
        isImage: isImageInput
      },
      {
        onSuccess: (res) => {
          const id = res?.data[0].id;
          onChange(id);
          setFileName(file?.name);
          if (isImageInput) {
            setThumbUrl(res?.data[0].thumbUrl);
          }
        }
      }
    );
  };

  const handleRemoveFile = () => {
    setFileName('');
    setThumbUrl('');
    setFormValue(input.field.key, '');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const renderButtonText = () => {
    if (isPending) {
      return t('components.views.form.file_input.uploading');
    }
    if (isImageInput) {
      if (fileName) {
        return t('components.views.form.file_input.replace');
      }
      return t('components.views.form.file_input.upload_image');
    }
    if (fileName) {
      return t('components.views.form.file_input.replace');
    }
    return t('components.views.form.file_input.choose_file');
  };

  if (isExternalImageSource) {
    return (
      <Input
        placeholder={t('components.views.form.file_input.enter_external_url')}
        {...register(input.field.key)}
      />
    );
  }

  return (
    <>
      {isImageInput && thumbUrl && (
        <img
          src={thumbUrl}
          alt={fileName}
          className={cn('size-20', {
            'rounded-lg': theme.appearance.corners === 'rounded'
          })}
        />
      )}
      <div className="flex items-center gap-2">
        <Controller
          name={input.field.key}
          render={({ field: { onChange } }) => (
            <input
              disabled={isReadOnly}
              ref={fileInputRef}
              className="hidden"
              type="file"
              data-testid="form-view-file-input"
              onChange={(e) => handleFileChange(e, onChange)}
              {...(isImageInput && { accept: imageAllowedFormatsString })}
            />
          )}
        />
        <Button
          id={`${view.key}-${input.id}-file-upload`}
          disabled={isReadOnly}
          intent="secondary"
          size="sm"
          onClick={handleButtonClick}
          className={cn('shrink-0 gap-1', {
            'text-subtle': isPending
          })}
        >
          {isPending ? <Spinner /> : <UploadIcon size={16} className="text-default" />}
          {renderButtonText()}
        </Button>
        {fileName && (
          <div className="flex items-center">
            <span className="text-xs text-subtle">{fileName}</span>
            {!isReadOnly && (
              <Button intent="minimal" className="size-3" onClick={handleRemoveFile}>
                <TrashIcon size={14} className="shrink-0 text-destructive-emphasis" />
              </Button>
            )}
          </div>
        )}
      </div>

      <FormErrorMessage errors={errors} name={input.field.key} />
    </>
  );
}
