import { useQuery } from '@tanstack/react-query';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import { type KnackObjectKey } from '@/types/schema/KnackObject';
import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios, getAppBasedRequestHeaders } from '@/utils/axiosConfig';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { type ConnectionRecord } from '@/components/views/form/inputs/connection/types';
import { usePageContext } from '@/context/PageContext';
import { useApplicationQuery } from './useApplicationQuery';

interface ConnectionRecordResponse {
  total_pages: number;
  current_page: number;
  total_records: number;
  records: ConnectionRecord[];
}

async function getConnectionRecords({
  pageKey,
  viewKey,
  fieldKey,
  relationshipObjectKey,
  filters = [],
  applicationId
}: {
  pageKey: LiveAppPage['key'];
  viewKey: LiveAppViewKey;
  fieldKey: KnackFieldKey;
  relationshipObjectKey?: KnackObjectKey;
  filters?: KnackFilter[];
  applicationId?: string;
}) {
  const queryParams = new URLSearchParams({
    ...(filters.length && { filters: JSON.stringify(filters) })
  } as Record<string, string>);

  let url = `/v1/scenes/${pageKey}/views/${viewKey}/connections/${fieldKey}`;

  if (isInternalBuilderIframe() && applicationId && relationshipObjectKey) {
    // When iframed by the builder we need to fetch the records from the object endpoint
    url = `/v1/applications/${applicationId}/connections/${relationshipObjectKey}`;

    // We also want to limit the number of connected records returned since we don't need all of them
    queryParams.append('limit_return', 'true');
    queryParams.append('rows_per_page', '2000'); // 2000 is used to match parity with v3
  }

  const { data: records } = await axios.get<ConnectionRecordResponse>(url, {
    params: queryParams,
    withCredentials: true,
    headers: getAppBasedRequestHeaders()
  });

  return records;
}

export function useConnectionRecordsQuery({
  viewKey,
  relationshipObjectKey,
  fieldKey,
  enabled = true,
  filters = []
}: {
  viewKey: LiveAppViewKey;
  relationshipObjectKey?: KnackObjectKey;
  fieldKey: KnackFieldKey;
  enabled?: boolean;
  filters?: KnackFilter[];
}) {
  const { data: application } = useApplicationQuery();
  const { activePage } = usePageContext();

  if (!activePage) {
    throw new Error('useConnectionRecordsQuery must be used within a page context');
  }

  return useQuery({
    queryKey: [queryKeys.connectionRecord, fieldKey],
    queryFn: async () =>
      getConnectionRecords({
        pageKey: activePage.key,
        viewKey,
        fieldKey,
        relationshipObjectKey,
        filters,
        applicationId: application?.id
      }),
    refetchOnWindowFocus: false,
    enabled
  });
}
