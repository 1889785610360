import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { HiArrowSmallRight as ArrowIcon } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

import { PageError } from '@/components/errors/PageError';
import { ROUTES } from '@/Router';

export function NotFoundPage() {
  const [t, { language }] = useTranslation();

  return (
    <main className="kn-page-not-found container flex min-h-screen flex-col items-center justify-center">
      <PageError
        title="404"
        message={t('components.not_found.message')}
        button={
          <Button className="gap-1" asChild>
            <Link to={ROUTES.ROOT}>
              {t('components.not_found.button_text')}
              <ArrowIcon size={14} />
            </Link>
          </Button>
        }
      />
      <Helmet>
        <title lang={language}>{`${t('titles.not_found')} - ${t('titles.app_name')}`}</title>
      </Helmet>
    </main>
  );
}
