import { useCallback } from 'react';

import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { getDefaultCriteriaDateTimeValue } from '@/utils/date-time-criteria-helpers';

export function useCriteriaHelpers() {
  const { getBaseFieldOperators, canFieldStoreDateValues } = useFieldHelpers();

  const getDefaultCriteriaValue = useCallback(
    (field: KnackField) => {
      if (field.type === 'connection') {
        return [] as string[];
      }

      if (field.type === 'boolean') {
        return true;
      }

      if (canFieldStoreDateValues(field)) {
        return getDefaultCriteriaDateTimeValue(field);
      }

      return '';
    },
    [canFieldStoreDateValues]
  );

  const getDefaultCriteriaOperator = useCallback(
    (field: KnackField) => {
      const availableOperators = getBaseFieldOperators({ field });
      return availableOperators[0];
    },
    [getBaseFieldOperators]
  );

  // If either the new or previously selected field is a type that has a complex value (e.g. connection, boolean, multiple_choice), we need to clear the criteria value.
  // This prevents displaying the previous value in the input when changing the field type (e.g. changing from `connection` to `short_text` would show the previously selected record ID in the input), and also ensures that the value is in the correct format for the new field type.
  const shouldResetCriteriaValue = (
    newSelectedField: KnackField,
    previousSelectedField: KnackField
  ) => {
    const fieldTypesWithComplexValues: KnackFieldType[] = [
      'boolean',
      'multiple_choice',
      'connection',
      'date_time',
      'equation'
    ];

    return (
      fieldTypesWithComplexValues.includes(newSelectedField.type) ||
      fieldTypesWithComplexValues.includes(previousSelectedField.type)
    );
  };

  const shouldResetCriteriaOperator = (
    selectedField: KnackField,
    existingOperator: KnackOperator
  ) => {
    const availableOperators = getBaseFieldOperators({ field: selectedField });

    // If the field changes, the selected operator might not be in the new list of operators, so we select the operator in the list if that's the case
    const isOperatorInList = availableOperators.includes(existingOperator);

    return !isOperatorInList;
  };

  return {
    getDefaultCriteriaValue,
    getDefaultCriteriaOperator,
    shouldResetCriteriaValue,
    shouldResetCriteriaOperator
  };
}
