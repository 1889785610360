import { type BryntumCalendarProps } from '@bryntum/calendar-react';
import { t } from 'i18next';

import { type CalendarProps } from './type';

const getCalendarDefaultProps = ({
  weekStartDay,
  today,
  dayEndTime,
  dayStartTime,
  hideNonWorkingDays,
  mode,
  disableEventEditFeature,
  disableEventCreate
}: Partial<CalendarProps>): BryntumCalendarProps => ({
  id: `calendar_${Date.now().toString()}`,
  date: today,
  sidebar: false,
  height: 'calc(100vh - 200px)',
  modes: {
    year: false,
    agenda: {
      emptyText: t('components.views.calendar.loading_events'),
      listRangeMenu: {
        items: {
          listRangeYearItem: null,
          listRangeDecadeItem: null
        }
      },
      eventRenderer(details) {
        const { eventRecord } = details;
        return {
          tag: 'div',
          children: [
            {
              html: eventRecord.name
            }
          ]
        };
      }
    },
    day: {
      dayStartTime,
      dayEndTime,
      eventRenderer(details) {
        const { eventRecord } = details;
        return {
          html: eventRecord.name
        };
      }
    },
    week: {
      dayStartTime,
      dayEndTime,
      eventRenderer(details) {
        const { eventRecord } = details;
        return {
          html: eventRecord.name
        };
      }
    },
    month: {
      autoRowHeight: true,
      maxEventsPerCell: 6,
      eventRenderer(details) {
        const { eventRecord } = details;
        return {
          html: eventRecord.name
        };
      }
    }
  },
  mode,
  weekStartDay,
  hideNonWorkingDays,
  // additional feature for future scope
  eventCopyPasteFeature: {
    disabled: true
  },
  eventEditFeature: {
    editorConfig: {
      autoClose: false,
      bbar: {
        items: {
          // disabled delete button from edit form view
          deleteButton: false
        }
      }
    },
    // removes all the default bryntum default form fields
    items: {
      resourceField: false,
      endDateField: false,
      endTimeField: false,
      nameField: false,
      startDateField: false,
      startTimeField: false,
      repeatField: false,
      allDay: false,
      recurrenceCombo: false,
      editRecurrenceButton: false
    }
  },
  eventMenuFeature: {
    items: {
      // disabled delete option from calendar view
      deleteEvent: null,
      // additional feature for future scope
      duplicate: null,
      editEvent: !disableEventEditFeature
    }
  },
  autoCreate: !disableEventCreate,
  dragFeature: {
    creatable: !disableEventCreate,
    draggable: !disableEventEditFeature
  },
  scheduleMenuFeature: {
    disabled: disableEventCreate
  }
});

export { getCalendarDefaultProps };
