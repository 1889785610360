import { type KnackObjectResponse } from '@/types/schema/KnackObject';

export const tableDemo: KnackObjectResponse = {
  id: '665ddda7059a2f04c2b6a665',
  key: 'object_1',
  name: 'SuperForm',
  fields: [
    {
      type: 'short_text',
      required: true,
      default: 'Input Text',
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665ddda7059a2f04c2b6a667',
      name: 'Name',
      key: 'field_54'
    },
    {
      type: 'paragraph_text',
      required: false,
      unique: false,
      default: 'Input Text',
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddac059a2f04c2b6a674',
      name: 'Paragraph Text',
      key: 'field_55'
    },
    {
      type: 'rich_text',
      required: false,
      unique: false,
      default: 'Placeholder Text',
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddae059a2f04c2b6a67f',
      name: 'Rich Text',
      key: 'field_56'
    },
    {
      type: 'number',
      required: false,
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddb1059a2f04c2b6a68a',
      name: 'Number',
      format: {
        format: 'none',
        mark_decimal: 'none',
        mark_thousands: 'none',
        precision: 2,
        rounding: 'none'
      },
      key: 'field_57'
    },
    {
      type: 'currency',
      required: false,
      unique: false,
      default: '1234.56',
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddb3059a2f04c2b6a695',
      name: 'Currency',
      format: {
        format: '$'
      },
      key: 'field_58'
    },
    {
      type: 'multiple_choice',
      required: false,
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddb7059a2f04c2b6a6a0',
      name: 'Multiple Choice',
      format: {
        blank: 'Select...',
        default: 'kn-blank',
        options: ['First Choice', 'Second Choice', 'Third Choice'],
        sorting: 'alphabetical',
        type: 'multi'
      },
      key: 'field_59'
    },
    {
      type: 'boolean',
      required: false,
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddb9059a2f04c2b6a6ab',
      name: 'Yes/No',
      format: {
        default: false,
        format: 'yes_no',
        input: 'dropdown',
        required: false
      },
      default: false,
      key: 'field_60'
    },
    {
      type: 'date_time',
      required: false,
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddbb059a2f04c2b6a6b6',
      name: 'Date/Time',
      format: {
        calendar: false,
        date_format: 'mm/dd/yyyy',
        default_time: '',
        default_type: 'current',
        time_format: 'Ignore Time',
        time_type: 'current'
      },
      key: 'field_61'
    },
    {
      type: 'date_time',
      required: false,
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddc3059a2f04c2b6a6c1',
      name: 'Date/Time 2',
      format: {
        calendar: true,
        date_format: 'mm/dd/yyyy',
        default_time: '',
        default_type: 'current',
        time_format: 'HH:MM am',
        time_type: 'current'
      },
      key: 'field_62'
    },
    {
      type: 'file',
      required: false,
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddc5059a2f04c2b6a6cc',
      name: 'File',
      format: {
        secure: false
      },
      key: 'field_63'
    },
    {
      type: 'name',
      required: false,
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddca059a2f04c2b6a6d7',
      name: 'Person',
      format: {
        format: 'Title First Last'
      },
      key: 'field_64'
    },
    {
      type: 'address',
      required: false,
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddce059a2f04c2b6a6e2',
      name: 'Address',
      format: {
        enable_autocomplete: false,
        format: 'US',
        input: 'address'
      },
      key: 'field_65'
    },
    {
      type: 'link',
      required: false,
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddcf059a2f04c2b6a6ed',
      name: 'Link',
      format: {
        target: '_self',
        text_format: 'url'
      },
      key: 'field_66'
    },
    {
      type: 'signature',
      required: false,
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddd1059a2f04c2b6a6f8',
      name: 'Signature',
      key: 'field_67'
    },
    {
      type: 'rating',
      required: false,
      unique: false,
      default: '3',
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddd3059a2f04c2b6a703',
      name: 'Rating',
      format: {
        allow_half: false,
        max: '5',
        method: 'stars',
        min: 1,
        precision: 2
      },
      key: 'field_68'
    },
    {
      type: 'phone',
      required: false,
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665dddd5059a2f04c2b6a70e',
      name: 'Phone',
      format: {
        extension: false,
        format: '(999) 999-9999'
      },
      key: 'field_69'
    },
    {
      type: 'short_text',
      required: true,
      default: 'Input Text',
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665ddda7059a2f04c2b6a667',
      name: 'User',
      key: 'field_22'
    }
  ]
};
