import { type ReportView } from '@/types/schema/views/ReportView';

export const pieChartViewDemoView: ReportView = {
  id: '670533cf7274b9015aa4cc77',
  design: {},
  columns: [],
  links: [],
  inputs: [],
  type: 'report',
  name: 'Pie Chart',
  source: {
    criteria: {
      match: 'all',
      rules: [],
      groups: []
    },
    limit: '',
    object: 'object_1',
    sort: []
  },
  title: 'Pie Chart Title',
  description: '',
  rows: [
    {
      id: 'row_1',
      reports: [
        {
          id: 'report_1',
          title: '',
          type: 'pie',
          source: {
            criteria: {
              match: 'all',
              rules: [],
              groups: []
            },
            limit: '',
            object: 'object_1'
          },
          filters: {
            filter_type: 'none',
            preset_filters: [],
            menu_filters: []
          },
          groups: [
            {
              field: 'field_54',
              label: 'Name 2',
              type: 'short_text',
              group: 'short_text-field_54'
            }
          ],
          calculations: [
            {
              field: 'field_57',
              calculation: 'total',
              label: 'Total',
              field_type: 'number',
              field_format: {
                format: ''
              }
            }
          ],
          description: '',
          options: {
            exclude_empties: false,
            hide_negatives: false,
            child_records: false,
            export_links: false,
            shouldShowDataTable: false
          },
          layout: {
            dimensions: 'auto',
            chart_width: '500',
            chart_height: '350',
            legend_width: '170',
            legend: 'below',
            stacking: 'none',
            tilt_labels: false,
            data_labels: true,
            shouldShowTotal: true,
            line_type: 'linear',
            bar_type: 'bar',
            pie_type: 'donut',
            colors: 'monochromatic',
            label_position: 'outside_with_line'
          },
          preview: false,
          settings: {
            export_links: false,
            shouldShowDataTable: false
          }
        }
      ],
      layout: 'one-col'
    }
  ],
  key: 'view_1'
};
