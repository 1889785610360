import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@knack/asterisk-react';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import type { KnackField } from '@/types/schema/KnackField';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useGeocodeQuery } from '@/hooks/api/queries/useGeocodeQuery';
import { type GeoOrigin } from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import type { FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { MapEmptyState } from '@/components/views/map/engine/MapEmptyState';
import { GoogleMap } from '@/components/views/map/engine/providers/GoogleMap';
import { MapLibreMap } from '@/components/views/map/engine/providers/MapLibreMap';

export type MapContentProps = {
  addressFieldKey: string;
  fields?: KnackField[];
  formattedViewRecords?: FormattedViewRecord[];
  isEmptyState?: boolean;
  isLoading?: boolean;
  isSearchTermValid?: boolean;
  onSelectRecord?: (record: FormattedViewRecord) => void;
  onUnselectRecord?: () => void;
  pinColorDefault?: string;
  pinColors?: KnackCriteria[];
  searchTerm?: string;
  searchCoordinates?: GeoOrigin;
  selectedRecord?: FormattedViewRecord;
  titleFieldKey?: string;
};

export function MapContent({
  formattedViewRecords = [],
  selectedRecord,
  onSelectRecord,
  onUnselectRecord,
  addressFieldKey,
  titleFieldKey,
  pinColorDefault = '#FE7569',
  pinColors = [],
  isEmptyState,
  fields = [],
  isLoading,
  searchTerm = 'default',
  isSearchTermValid = true,
  searchCoordinates
}: MapContentProps) {
  const [t] = useTranslation();
  const { data: application, isLoading: isLoadingApplication } = useApplicationQuery();

  const { data: geocodedSearchTerm, isLoading: isLoadingGeocodeSearchTerm } = useGeocodeQuery({
    query: searchTerm,
    enabled: !searchCoordinates && searchTerm !== 'default'
  });

  const defaultAddressCooordinates: GeoOrigin = useMemo(
    () =>
      searchCoordinates || {
        latitude: geocodedSearchTerm?.latitude || 0,
        longitude: geocodedSearchTerm?.longitude || 0
      },
    [geocodedSearchTerm, searchCoordinates]
  );

  if (isLoadingApplication) {
    return <Spinner />;
  }
  if (!application) {
    return (
      <MapEmptyState>
        <p>{t('components.views.map.errors.could_not_load_map')}</p>
      </MapEmptyState>
    );
  }
  if (formattedViewRecords.length === 0 && (isEmptyState || !searchTerm))
    return (
      <MapEmptyState>
        <p>{t('components.views.map.errors.empty_search')}</p>
      </MapEmptyState>
    );
  if ((isLoading || isLoadingGeocodeSearchTerm) && formattedViewRecords.length === 0) {
    return (
      <MapEmptyState>
        <p>{t('components.views.map.searching')}</p>
      </MapEmptyState>
    );
  }
  if (searchTerm && !isSearchTermValid) {
    return (
      <MapEmptyState>
        <p>{t('components.views.map.errors.location_not_found')}</p>
        <p>{t('components.views.map.errors.change_search_term')}</p>
      </MapEmptyState>
    );
  }

  return application.settings.mapsAndGeocoderProvider === 'google' ? (
    <GoogleMap
      formattedViewRecords={formattedViewRecords}
      selectedRecord={selectedRecord}
      onSelectRecord={onSelectRecord}
      onUnselectRecord={onUnselectRecord}
      addressFieldKey={addressFieldKey}
      titleFieldKey={titleFieldKey}
      pinColorDefault={pinColorDefault}
      pinColors={pinColors}
      fields={fields}
      defaultAddress={defaultAddressCooordinates}
      googleMapId={application.settings.googleMapId}
    />
  ) : (
    <MapLibreMap
      formattedViewRecords={formattedViewRecords}
      selectedRecord={selectedRecord}
      onSelectRecord={onSelectRecord}
      onUnselectRecord={onUnselectRecord}
      addressFieldKey={addressFieldKey}
      titleFieldKey={titleFieldKey}
      pinColorDefault={pinColorDefault}
      pinColors={pinColors}
      fields={fields}
      defaultAddress={defaultAddressCooordinates}
    />
  );
}
