import { useState, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiArrowDownOnSquare as ExportIcon,
  HiFunnel as FilterIcon,
  HiPlus as PlusIcon,
  HiRss as SubscribeIcon
} from 'react-icons/hi2';
import { type EventModel } from '@bryntum/calendar';
import { Button, InputSearch } from '@knack/asterisk-react';

import { type CalendarUtilsProps } from './type';

const defaultButtonState: Required<NonNullable<CalendarUtilsProps['buttons']>> = {
  shouldEnableEvent: true,
  shouldEnableExport: true,
  shouldEnableSubscribe: true,
  shouldEnableFilter: true
};

export function CalendarUtils({
  shouldEnableSearch = true,
  buttons,
  calendarInstance
}: CalendarUtilsProps) {
  const { shouldEnableEvent, shouldEnableExport, shouldEnableSubscribe, shouldEnableFilter } = {
    ...buttons,
    ...defaultButtonState
  };
  const [searchText, setSearchText] = useState('');
  const [t] = useTranslation('translation', { keyPrefix: 'components.views.calendar' });

  const handleSearch = async (e: ChangeEvent<HTMLInputElement>) => {
    const eventStore = calendarInstance?.eventStore;
    if (!eventStore) {
      return;
    }

    const searchValue = e.target.value;
    setSearchText(searchValue);

    // clear filter after every new input
    await eventStore.clearFilters();

    await eventStore.filter({
      filterBy: (data: EventModel) => data.name.toLowerCase().includes(searchValue.toLowerCase())
    });
  };

  const handleAddEvent = async () => {
    if (!calendarInstance) {
      return;
    }

    await calendarInstance?.eventStore.clearFilters();
    setSearchText('');

    calendarInstance.editEvent({
      name: t('new_event'), // Prefilled event name
      startDate: new Date(), // Start time
      endDate: new Date(new Date().getTime() + 60 * 60 * 1000) // 1 hour later
    } as EventModel);
  };

  const handleFilter = () => {
    if (calendarInstance) {
      calendarInstance.setConfig({
        hideNonWorkingDays: !calendarInstance.hideNonWorkingDays
      });
    }
  };

  return (
    <div className="flex justify-between bg-card px-4 pb-4 pt-6">
      <div className="flex gap-2">
        {shouldEnableEvent && (
          <Button intent="primary" size="default" onClick={handleAddEvent}>
            <Button.Icon icon={PlusIcon} />
            {t('add_event')}
          </Button>
        )}
        {shouldEnableExport && (
          <Button intent="secondary" size="default">
            <Button.Icon icon={ExportIcon} />
            {t('export')}
          </Button>
        )}
        {shouldEnableSubscribe && (
          <Button intent="secondary" size="default">
            <Button.Icon icon={SubscribeIcon} />
            {t('subscribe')}
          </Button>
        )}
        {shouldEnableFilter && (
          <Button intent="secondary" size="default" onClick={handleFilter}>
            <Button.Icon icon={FilterIcon} />
            {t('filter')}
          </Button>
        )}
      </div>
      <div>
        {shouldEnableSearch && (
          <InputSearch placeholder={t('search')} onChange={handleSearch} value={searchText} />
        )}
      </div>
    </div>
  );
}
