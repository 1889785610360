import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { useViewSearchParams } from '@/hooks/useViewSearchParams';
import { cn } from '@/utils/tailwind';
import { RowsPerPageSelect } from '@/components/views/view-options/RowsPerPageSelect';
import { SelectPagination } from '@/components/views/view-options/SelectPagination';
import { useViewContext } from '@/components/views/ViewContext';
import { RecordsRange } from './RecordsRange';

type SmallViewFooterProps = {
  shouldAllowChangeRowsPerPage: boolean;
  shouldRenderPagination: boolean;
  totalPageNumber: number;
  totalRecords?: number;
};

export function SmallViewFooter({
  shouldAllowChangeRowsPerPage,
  shouldRenderPagination,
  totalPageNumber,
  totalRecords
}: SmallViewFooterProps) {
  const { view } = useViewContext<MapView | ListView>();
  const { currentPage, rowsPerPage, setViewParam } = useViewSearchParams(view);
  return (
    <div
      className={cn(
        'flex w-full items-center',
        shouldAllowChangeRowsPerPage || (!shouldAllowChangeRowsPerPage && totalRecords)
          ? 'justify-between'
          : 'justify-end'
      )}
    >
      {shouldAllowChangeRowsPerPage && (
        <div>
          <RowsPerPageSelect />
        </div>
      )}
      {!shouldAllowChangeRowsPerPage && totalRecords && rowsPerPage && (
        <RecordsRange
          totalRecords={totalRecords}
          rowsPerPage={Number(rowsPerPage)}
          currentPage={Number(currentPage)}
        />
      )}
      {shouldRenderPagination && (
        <SelectPagination
          totalPages={totalPageNumber}
          currentPage={Number(currentPage)}
          onPageChange={(page) => setViewParam({ page })}
        />
      )}
    </div>
  );
}
