import { useTranslation } from 'react-i18next';

type RecordsRangeProps = {
  totalRecords: number;
  rowsPerPage: number;
  currentPage: number;
};

export function RecordsRange({ totalRecords, rowsPerPage, currentPage }: RecordsRangeProps) {
  const [t] = useTranslation();
  const firstRecord = (currentPage - 1) * rowsPerPage + 1;
  const lastRecord = Math.min(currentPage * rowsPerPage, totalRecords);

  return (
    <div className="text-xs text-subtle">
      {t('components.views.records_range', {
        firstRecord,
        lastRecord,
        totalRecords
      })}
    </div>
  );
}
