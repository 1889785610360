import React from 'react';
import { HiChevronRight as ArrowIcon } from 'react-icons/hi2';
import { Link } from 'react-router-dom';

import { usePageSegmentsHelpers } from '@/hooks/helpers/usePageSegmentsHelpers';
import { cn } from '@/utils/tailwind';
import { usePageContext } from '@/context/PageContext';

export function Breadcrumbs() {
  const { pageSegments } = usePageContext();
  const { getSegmentPath } = usePageSegmentsHelpers();

  // Skip showing breadcrumbs if there is one or zero segments in the URL
  if (pageSegments.length <= 1) {
    return null;
  }

  const linkClasses = 'max-w-40 truncate';

  return (
    <div className="kn-breadcrumbs mb-4 flex items-center gap-2">
      {pageSegments.map((pageSegment, pageSegmentIndex) => {
        // If it's the last segment we don't show it as a link
        if (pageSegmentIndex === pageSegments.length - 1) {
          return (
            <span key={pageSegment.slug} className={cn('text-brand-default', linkClasses)}>
              {pageSegment.name}
            </span>
          );
        }

        return (
          <React.Fragment key={pageSegment.slug}>
            <Link
              to={getSegmentPath(pageSegmentIndex)}
              className={cn('hover:text-brand-default', linkClasses)}
            >
              {pageSegment.name}
            </Link>
            <ArrowIcon />
          </React.Fragment>
        );
      })}
    </div>
  );
}
