import { cn } from '@/utils/tailwind';

export function PageSection({
  children,
  isCollapsed
}: {
  children: React.ReactNode;
  isCollapsed?: boolean;
}) {
  return (
    <section
      className={cn('kn-page-section flex w-full flex-wrap gap-4 sm:flex-nowrap', {
        hidden: isCollapsed
      })}
    >
      {children}
    </section>
  );
}
