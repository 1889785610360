import { z } from 'zod';

import { type KnackField } from '@/types/schema/KnackField';
import { type LiveAppApplicationPasswordOptions } from '@/types/schema/LiveAppApplication';
import { type FormViewPasswordInput } from '@/types/schema/views/form/Password';
import { type FormViewInput } from '@/types/schema/views/FormView';
import {
  addressInputSchema,
  booleanInputSchema,
  connectionInputSchema,
  currencyInputSchema,
  dateTimeInputSchema,
  emailInputSchema,
  equationInputSchema,
  fileInputSchema,
  linkInputSchema,
  multiChoiceInputSchema,
  nameInputSchema,
  numberInputSchema,
  paragraphTextInputSchema,
  passwordInputSchema,
  phoneInputSchema,
  ratingInputSchema,
  richTextInputSchema,
  shortTextInputSchema,
  signatureInputSchema,
  timerInputSchema,
  userRolesInputSchema
} from './inputSchemas';

interface DynamicFormSchemaProps {
  inputs: FormViewInput[];
  sourceTableFields: KnackField[] | undefined;
  passwordOptions?: LiveAppApplicationPasswordOptions;
}

export function getDynamicFormSchema({
  inputs,
  sourceTableFields = [],
  passwordOptions
}: DynamicFormSchemaProps) {
  const schemaObject = inputs.reduce<{ [key: string]: any }>((acc, input) => {
    if (input.type === 'section_break' || input.type === 'divider') return acc;

    const field = sourceTableFields.find((f) => f.key === input.field.key);

    if (!field) return acc;

    const isRequired = field.required;
    const isReadOnly = input.read_only ?? false;

    let inputSchema;

    switch (field.type) {
      case 'short_text':
        inputSchema = shortTextInputSchema({ isRequired, isReadOnly });
        break;
      case 'rich_text':
        inputSchema = richTextInputSchema({ isRequired, isReadOnly });
        break;
      case 'paragraph_text': {
        inputSchema = paragraphTextInputSchema({ isRequired, isReadOnly });
        break;
      }
      case 'number':
        inputSchema = numberInputSchema({ isRequired, isReadOnly });
        break;
      case 'currency':
        inputSchema = currencyInputSchema({ isRequired, isReadOnly });
        break;
      case 'equation':
        inputSchema = equationInputSchema();
        break;
      case 'multiple_choice':
        inputSchema = multiChoiceInputSchema({ isRequired, isReadOnly });
        break;
      case 'boolean':
        inputSchema = booleanInputSchema({ isRequired, isReadOnly });
        break;
      case 'file':
        inputSchema = fileInputSchema({ isRequired, isReadOnly });
        break;
      case 'image':
        inputSchema = fileInputSchema({ isRequired, isReadOnly });
        break;
      case 'name':
        inputSchema = nameInputSchema({ isRequired, isReadOnly });
        break;
      case 'email':
        inputSchema = emailInputSchema({ isRequired, isReadOnly });
        break;
      case 'date_time':
        inputSchema = dateTimeInputSchema({ isRequired, isReadOnly, inputFormat: field.format });
        break;
      case 'timer':
        inputSchema = timerInputSchema({ isRequired, isReadOnly });
        break;
      case 'address':
        inputSchema = addressInputSchema({ isRequired, isReadOnly, inputFormat: field.format });
        break;
      case 'phone':
        inputSchema = phoneInputSchema({ isRequired, isReadOnly });
        break;
      case 'link':
        inputSchema = linkInputSchema({ isRequired, isReadOnly });
        break;
      case 'rating':
        inputSchema = ratingInputSchema({ isRequired, isReadOnly });
        break;
      case 'signature':
        inputSchema = signatureInputSchema({ isRequired, isReadOnly });
        break;
      case 'connection':
        inputSchema = connectionInputSchema({ isRequired, isReadOnly });
        break;
      case 'password':
        inputSchema = passwordInputSchema(
          (input as FormViewPasswordInput).action_authenticate,
          passwordOptions
        );
        break;
      case 'user_roles':
        inputSchema = userRolesInputSchema();
        break;
      default:
        // If the field type is not supported by the schema, don't validate
        inputSchema = z.any();
        break;
    }

    // Use the field key as the key in the schema object
    acc[input.field.key] = inputSchema;
    return acc;
  }, {});

  return z.object(schemaObject);
}
