import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiMiniFunnel as FiltersIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Popover } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackCriteriaDateTimeValue } from '@/types/schema/KnackCriteria';
import { type KnackField } from '@/types/schema/KnackField';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type TableView } from '@/types/schema/views/TableView';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useViewFilters, type ViewFilters } from '@/hooks/useViewFilters';
import { useViewSearchParams, type ViewWithSearchParams } from '@/hooks/useViewSearchParams';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { ViewFilterRow } from './ViewFilterRow';

interface ViewFilterProps {
  view: TableView | ListView | MapView;
  eligibleCriteriaFields: KnackField[];
}

export function ViewFiltersPopover({ view, eligibleCriteriaFields }: ViewFilterProps) {
  const [t] = useTranslation();

  const { theme } = useThemingContext();
  const { sourceTable } = useViewContext<ViewWithSearchParams>();
  const { activeViewFilters } = useViewSearchParams(view);
  const { saveActiveFilters, resetFilters, getDefaultFilters } = useViewFilters({
    view
  });
  const { getBaseFieldOperators, canFieldStoreDateValues } = useFieldHelpers();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const defaultFilters = getDefaultFilters(eligibleCriteriaFields);

  const filterFormSchema = z.custom<ViewFilters>().superRefine((data, context) => {
    if (!data.match) {
      context.addIssue({
        path: ['match'],
        code: 'custom',
        message: t('errors.value_required')
      });
    }
  });

  const form = useForm<ViewFilters>({
    resolver: zodResolver(filterFormSchema),
    defaultValues: activeViewFilters || defaultFilters
  });

  const {
    fields: criteriaFormFields,
    append: appendCriteria,
    update: updateCriteria,
    remove: removeCriteria
  } = useFieldArray({
    control: form.control,
    name: 'rules'
  });

  const handleAddNewFilter = () => {
    appendCriteria({ ...defaultFilters.rules[0] });
  };

  const handleClickClearAll = () => {
    form.setValue('rules', [defaultFilters.rules[0]]);
    resetFilters();
    setIsPopoverOpen(false);
  };

  const onPopoverOpenChange = (open: boolean) => {
    setIsPopoverOpen(open);

    // Reset the filters form when the popover is closed without
    if (!open) {
      form.reset();
    }
  };

  const onSubmit = (viewFilters: ViewFilters) => {
    const normalizedViewFilters: ViewFilters = { ...viewFilters };

    normalizedViewFilters.rules = viewFilters.rules.map((rule) => {
      const criteriaField = sourceTable.fields.find((field) => field.key === rule.field);

      if (!criteriaField) {
        return rule;
      }

      if (criteriaField.type === 'connection' && Array.isArray(rule.value)) {
        return {
          ...rule,
          value: [rule.value[0]]
        };
      }

      if (canFieldStoreDateValues(criteriaField) && rule.value) {
        const ruleDateTimeValue = rule.value as KnackCriteriaDateTimeValue;
        return {
          ...rule,
          value: ruleDateTimeValue
        };
      }

      return rule;
    });

    saveActiveFilters(normalizedViewFilters);
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    form.reset(activeViewFilters || defaultFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeViewFilters]);

  return (
    <Popover open={isPopoverOpen} onOpenChange={onPopoverOpenChange}>
      <Popover.Trigger asChild>
        <Button
          data-testid="view-filters-button"
          intent="secondary"
          className="h-9 gap-2 text-default md:w-auto"
          disabled={defaultFilters.rules.length === 0}
          {...(isInternalBuilderIframe() && { onClick: (e) => e.preventDefault() })}
        >
          <FiltersIcon size={16} />
          <span className="hidden md:inline">{t('actions.filter')}</span>
          {activeViewFilters && (
            <span
              className={cn('block size-4 bg-subtle text-xs text-subtle md:hidden', {
                rounded: theme.appearance.corners === 'rounded'
              })}
            >
              {criteriaFormFields.length}
            </span>
          )}
        </Button>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        className="max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popover-content-available-width)] max-w-[800px] overflow-auto p-4"
        collisionPadding={32}
      >
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            {criteriaFormFields.length > 0 && (
              <div className="mb-4 flex flex-col gap-4 sm:gap-2">
                {criteriaFormFields.map((rule, ruleIndex) => {
                  const criteriaField = sourceTable.fields.find(
                    (field) => field.key === rule.field
                  );

                  if (!criteriaField) {
                    return null;
                  }

                  const criteriaFieldOperators = getBaseFieldOperators({
                    field: criteriaField,

                    // We don't want to show the "near" operator for map views, since the distance filtering is handled differently
                    shouldExcludeNearOperator: view.type === 'map'
                  });

                  return (
                    <ViewFilterRow
                      key={rule.id}
                      ruleIndex={ruleIndex}
                      criteriaField={criteriaField}
                      criteriaFieldOperators={criteriaFieldOperators}
                      eligibleCriteriaFields={eligibleCriteriaFields}
                      sourceObject={sourceTable}
                      onCriteriaUpdate={(updatedCriteria) => {
                        updateCriteria(ruleIndex, updatedCriteria);
                        form.clearErrors();
                      }}
                      onCriteriaRemove={() => removeCriteria(ruleIndex)}
                    />
                  );
                })}
              </div>
            )}

            <Button intent="secondary" onClick={handleAddNewFilter}>
              <AddIcon size={16} className="mr-2 shrink-0" />
              {t('components.view_filters.add_condition')}
            </Button>
          </form>
        </FormProvider>
        <div className="flex justify-end gap-2 pt-2">
          <Button intent="minimal" onClick={handleClickClearAll}>
            {t('actions.clear_all')}
          </Button>
          <Button intent="primary" onClick={form.handleSubmit(onSubmit)}>
            {t('actions.save')}
          </Button>
        </div>
      </Popover.Content>
    </Popover>
  );
}
