import { useTranslation } from 'react-i18next';
import { Spinner } from '@knack/asterisk-react';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import type {
  ViewActionRule,
  ViewColumnIcon,
  ViewColumnType,
  ViewLinkColumnType
} from '@/types/schema/LiveAppView';
import {
  type TableView,
  type TableViewColumn,
  type TableViewDisplayRule
} from '@/types/schema/views/TableView';
import {
  formatViewRecordsResponse,
  useViewMultipleRecordsQuery,
  type ViewRecordsResponse
} from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { useViewSearchParams } from '@/hooks/useViewSearchParams';
import { isPageEditor } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { ViewFooterSection } from '@/components/views/view-footer/ViewFooterSection';
import { ViewToolbarSection } from '@/components/views/view-options/ViewToolbarSection';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { useThemingContext } from '@/context/ThemingContext';
import { calculateSummaries, getEmptyColumns } from './helper';
import { SortableTableWrapper } from './table-sortable/SortableTableWrapper';
import { TableElement } from './TableElement';

export interface FormattedTableViewColumn {
  id: string;
  key: KnackFieldKey | 'id'; // A table record comes with one id and multiple field_${key} keys
  type: ViewColumnType;
  header: string;
  targetPage: string | undefined;
  linkText: string;
  linkType: ViewLinkColumnType;
  icon: ViewColumnIcon;
  actionRules?: ViewActionRule[];
  displayRules: TableViewDisplayRule[];
  shouldIgnoreSummary: boolean;
  shouldIgnoreEditing: boolean;
  width: TableViewColumn['width'];
  align: TableViewColumn['align'];
}

export function TableViewRender({ sourceData }: { sourceData?: ViewRecordsResponse }) {
  const [t] = useTranslation();
  const { theme } = useThemingContext();
  const { view, sourceTable } = useViewContext<TableView>();

  const {
    rowsPerPage,
    currentPage,
    searchValueFromParams,
    sortOrder,
    sortField,
    activeViewFilters
  } = useViewSearchParams(view);

  const { data: tableData, isLoading } = useViewMultipleRecordsQuery({
    view,
    objectKey: view.source.object,
    options: {
      rowsPerPage,
      page: currentPage,
      ...(searchValueFromParams && { search: searchValueFromParams }),
      ...(activeViewFilters && { filters: JSON.stringify(activeViewFilters) }),
      ...(sortOrder && { sortOrder }),
      ...(sortField && { sortField })
    }
  });

  const { totals: columnSummaries, columns: tableViewColumns } = view;
  const sourceTableFields = sourceTable.fields;
  const parsedTableData = sourceData ? formatViewRecordsResponse(sourceData) : tableData;
  const hasSummaries = columnSummaries && columnSummaries.length > 0;
  const formattedRecords = parsedTableData
    ? parsedTableData.records?.map((record) => ({
        values: record.values,
        rawValues: record.rawValues
      }))
    : [];
  // If we are in the page editor, we need to react to changes in the settings about showing a record limit
  const tableRecords =
    isPageEditor() && view.source.limit
      ? formattedRecords.slice(0, Number(view.source.limit))
      : formattedRecords;

  const { totalPages: totalPagesNumber = 1, totalRecords = 0 } = parsedTableData || {};
  // If we are in the page editor, we need to calculate the total records for the page editor and the total page number to be reactive
  const totalRecordsForPageEditor =
    Number(view.source.limit) > totalRecords ? totalRecords : Number(view.source.limit);
  const totalPageNumberForPageEditor = Math.ceil(
    (totalRecordsForPageEditor || totalRecords) / Number(rowsPerPage)
  );

  const summariesValues =
    hasSummaries && tableData ? calculateSummaries(tableData.records, sourceTableFields) : null;

  const emptyColumnsKeys = getEmptyColumns(formattedRecords);

  const availableTableColumns =
    view.hide_empty && emptyColumnsKeys.length > 0
      ? tableViewColumns.filter((column) => !emptyColumnsKeys.includes(column.field?.key))
      : tableViewColumns;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <ViewHeaderSection view={view} className="mb-4" />
      <ViewToolbarSection className="mb-4" />
      {availableTableColumns.length > 0 ? (
        <div
          className={cn('w-full overflow-hidden border border-default', {
            'overflow-y-auto': theme.tables.hasMaxHeight,
            'rounded-lg': theme.appearance.corners === 'rounded'
          })}
          style={{
            maxHeight: theme.tables.hasMaxHeight ? `${theme.tables.maxHeightPx}px` : undefined
          }}
        >
          <div className={cn('w-full overflow-auto')}>
            {isPageEditor() ? (
              <SortableTableWrapper
                columns={availableTableColumns}
                records={tableRecords}
                summariesValues={summariesValues}
              >
                <TableElement
                  columns={availableTableColumns}
                  records={tableRecords}
                  summariesValues={summariesValues}
                />
              </SortableTableWrapper>
            ) : (
              <TableElement
                columns={availableTableColumns}
                records={tableRecords}
                summariesValues={summariesValues}
              />
            )}
          </div>
          <ViewFooterSection
            totalPageNumber={isPageEditor() ? totalPageNumberForPageEditor : totalPagesNumber}
            className="bg-default"
          />
        </div>
      ) : (
        <div
          className={cn('border border-dashed p-2 text-center', {
            'rounded-lg': theme.appearance.corners === 'rounded'
          })}
        >
          {t('components.views.table.add_column_to_display')}
        </div>
      )}
    </>
  );
}
