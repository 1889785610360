import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { PageError } from '@/components/errors/PageError';

export function AppInMaintenance() {
  const [t, { language }] = useTranslation();

  return (
    <>
      <PageError
        title={t('components.app_in_maintenance.title')}
        message={t('components.app_in_maintenance.message')}
      />
      <Helmet>
        <title lang={language}>{t('components.app_in_maintenance.title')}</title>
      </Helmet>
    </>
  );
}
