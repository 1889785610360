import { useEffect } from 'react';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackField } from '@/types/schema/KnackField';
import { useCriteriaHelpers } from './helpers/useCriteriaHelpers';
import { useViewSearchParams, type ViewWithSearchParams } from './useViewSearchParams';

export interface ViewFilters {
  match: string;
  rules: KnackCriteria[];
}

export function useViewFilters({ view }: { view: ViewWithSearchParams }) {
  const { activeViewFilters, mapFilters, setViewParam } = useViewSearchParams(view);
  const { getDefaultCriteriaOperator, getDefaultCriteriaValue } = useCriteriaHelpers();

  const allowPresetFilters =
    view.type === 'map' ? view.details.allow_preset_filters : view.allow_preset_filters;
  const filterType = view.type === 'map' ? view.details.filter_type : view.filter_type;

  const getPresetFilters = () => {
    const presetFilters = view.type === 'map' ? view.details.preset_filters : view.preset_filters;
    return presetFilters.map((filter) => ({
      field: filter.field,
      operator: filter.operator,
      value: filter.value,
      range: filter.range,
      type: filter.type
    }));
  };

  const initializeFilters = () => {
    const shouldInitializeFilters =
      !activeViewFilters && allowPresetFilters && filterType !== 'menu' && filterType !== 'none';
    if (shouldInitializeFilters) {
      const presetFilters = getPresetFilters();
      if (presetFilters.length > 0) {
        setViewParam({ filters: { match: 'and', rules: presetFilters } });
      }
    }
  };

  useEffect(() => {
    initializeFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveActiveFilters = (filters: ViewFilters | null) => {
    let newFilters = filters;
    if (mapFilters && mapFilters.rules.length > 0) {
      const rules = filters?.rules || [];
      const mapRules = mapFilters.rules.filter((rule) => rule.operator === 'near');
      const filtersRules = rules.filter((rule) => rule.operator !== 'near');
      newFilters = {
        match: filters ? filters.match : 'and',
        rules: [...filtersRules, ...mapRules]
      };
    }

    setViewParam({ filters: newFilters, page: null });
  };

  const resetFilters = () => {
    setViewParam({ filters: mapFilters?.rules.length ? mapFilters : null });
  };

  const removeActiveFilter = (index: number) => {
    if (!activeViewFilters) return;

    if (activeViewFilters.rules.length === 1) {
      setViewParam({ filters: mapFilters?.rules.length ? mapFilters : null });
      return;
    }

    const newRules = activeViewFilters.rules.filter((_, i) => i !== index);
    if (newRules.length === 0) {
      setViewParam({ filters: mapFilters?.rules.length ? mapFilters : null });
      return;
    }

    const filtersRules = newRules.filter((rule) => rule.operator !== 'near');
    const mapRules = mapFilters?.rules.filter((rule) => rule.operator === 'near') || [];
    setViewParam({
      filters: {
        ...activeViewFilters,
        rules: [...filtersRules, ...mapRules]
      }
    });
  };

  const getDefaultFilters = (eligibleFilterCriteriaFields: KnackField[]) => {
    // If there are no eligible fields, return a filter with empty rules
    if (eligibleFilterCriteriaFields.length === 0) {
      const defaultFilters: ViewFilters = {
        match: 'and',
        rules: []
      };

      return defaultFilters;
    }

    const criteriaField = eligibleFilterCriteriaFields[0];
    const defaultFilters: ViewFilters = {
      match: 'and',
      rules: [
        {
          field: criteriaField.key,
          operator: getDefaultCriteriaOperator(criteriaField),
          value: getDefaultCriteriaValue(criteriaField)
        }
      ]
    };

    return defaultFilters;
  };

  return {
    saveActiveFilters,
    resetFilters,
    removeActiveFilter,
    getDefaultFilters,
    getPresetFilters
  };
}
