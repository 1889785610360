import { Divider, RichTextRenderer } from '@knack/asterisk-react';
import DOMPurify from 'dompurify';

import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { ListActionButton } from '@/components/views/action-button/ListActionButton';

interface DetailsStaticInputProps {
  input: DetailsViewInput;
  record: FormattedViewRecord | undefined;
}

export function DetailsStaticInput({ input, record }: DetailsStaticInputProps) {
  if (input.type === 'divider') {
    return <Divider key={input.id} className="w-full py-2" />;
  }

  if (input.type === 'special_title') {
    const sanitizedValue = DOMPurify.sanitize(input.copy);
    return (
      <div key={input.id}>
        <p>{input.value}</p>
        <RichTextRenderer dangerouslySetInnerHTML={{ __html: sanitizedValue }} />
      </div>
    );
  }

  if (input.type === 'action_link' || input.type === 'scene_link' || input.type === 'delete') {
    return (
      <div className="mr-1 inline-block" key={input.id}>
        <ListActionButton input={input} record={record} />
      </div>
    );
  }

  return null;
}
