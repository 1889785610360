import { type DetailsViewColumn, type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';
import { DetailsFieldInput } from './DetailsFieldInput';
import { DetailsStaticInput } from './DetailsStaticInput';

export function DetailsColumn({
  column,
  record
}: {
  column: DetailsViewColumn;
  record: FormattedViewRecord | undefined;
}) {
  const { theme } = useThemingContext();

  // We want to keep track of the inputs that have been rendered so we don't render them again, as multiple field inputs can be grouped together
  const renderedInputIds: string[] = [];

  return (
    <div className="max-w-full flex-1 space-y-2">
      {column.map((input, inputIndex) => {
        if (renderedInputIds.includes(input.id)) {
          return null;
        }

        renderedInputIds.push(input.id);

        if (input.type !== 'field') {
          return <DetailsStaticInput key={input.id} input={input} record={record} />;
        }

        // Find the inputs after this one that are of type 'field', until the next non-field input
        const nextPartOfArray = column.slice(inputIndex);
        const fieldInputs: DetailsViewInput[] = [];
        for (let i = 0; i < nextPartOfArray.length; i += 1) {
          const nextInput = nextPartOfArray[i];
          if (nextInput.type !== 'field') {
            break;
          }
          fieldInputs.push(nextInput);
        }

        if (fieldInputs.length === 0) {
          return null;
        }

        return (
          <div
            key={`input-field-group-starting-at-${input.id}`}
            className={cn('overflow-x-auto border border-default bg-card text-sm', {
              'rounded-lg': theme.appearance.corners === 'rounded'
            })}
          >
            {fieldInputs.map((fieldInput, fieldInputIndex) => {
              renderedInputIds.push(fieldInput.id);

              return (
                <DetailsFieldInput
                  key={fieldInput.id}
                  input={fieldInput}
                  record={record}
                  isLastInGroup={fieldInputIndex === fieldInputs.length - 1}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
