import { Tooltip } from '@knack/asterisk-react';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useThemingContext } from '@/context/ThemingContext';

export function NavBrand({
  navBrandRef,
  isVerticalNav
}: {
  navBrandRef?: React.RefObject<HTMLDivElement>;
  isVerticalNav?: boolean;
}) {
  const { data: application } = useApplicationQuery();
  const { theme } = useThemingContext();

  if (!application || !theme) return null;

  const logoWidth = 150 * (theme.mainNavContainer.logo.imageWidthPercentage / 100);
  const appName = theme.mainNavContainer.appTitle.isCustom
    ? theme.mainNavContainer.appTitle.customText
    : application?.name;
  const title = theme.mainNavContainer.appTitle.isVisible && (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <h1 className="max-w-96 truncate">{appName}</h1>
      </Tooltip.Trigger>
      <Tooltip.Content>{appName}</Tooltip.Content>
    </Tooltip>
  );

  const logo = theme.mainNavContainer.logo.isVisible && (
    <img
      src={theme.mainNavContainer.logo.url}
      alt={application?.name}
      style={{ minWidth: `${logoWidth}px`, maxWidth: `${logoWidth}px` }}
    />
  );

  if (isVerticalNav) {
    return (
      <div data-testid="vertical-nav-brand" className="flex flex-col gap-2 p-4" ref={navBrandRef}>
        {logo}
        {title}
      </div>
    );
  }

  // Horizontal or mobile nav
  return (
    <div
      data-testid="nav-brand"
      className="kn-brand mr-4 flex min-w-48 items-center gap-2 truncate"
      ref={navBrandRef}
    >
      {logo}
      {title}
    </div>
  );
}
