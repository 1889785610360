import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

export function SelectPagination({
  totalPages,
  currentPage,
  onPageChange
}: {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}) {
  const [t] = useTranslation();
  return (
    <Select
      value={currentPage.toString()}
      onValueChange={(value) => onPageChange(parseInt(value, 10))}
    >
      <Select.Trigger
        className="h-auto p-1 text-sm text-subtle"
        data-testid="select-pagination-page"
      />
      <Select.Content className="min-w-36" align="end">
        {Array.from({ length: totalPages }, (_, i) => (
          <Select.Item
            key={i + 1}
            value={(i + 1).toString()}
            className="text-sm"
            data-testid={`select-pagination-page-${i + 1}`}
          >
            {t('components.views.page_of', {
              page: i + 1,
              totalPages
            })}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
}
