import { type LiveAppPageColumn } from '@/types/schema/LiveAppPage';
import { cn } from '@/utils/tailwind';

export function PageColumn({
  column,
  children
}: {
  column: LiveAppPageColumn;
  children: React.ReactNode;
}) {
  const isColumnEmpty = column.viewKeys.length === 0;

  return (
    <div
      className={cn('kn-page-column flex w-full min-w-0 flex-1 flex-col gap-4', {
        'hidden sm:flex': isColumnEmpty,
        'basis-1/4': column.width === '25%',
        'basis-1/3': column.width === '33%',
        'basis-1/2': column.width === '50%',
        'basis-2/3': column.width === '66%',
        'basis-3/4': column.width === '75%'
      })}
      // Since we need to render empty columns for layout purposes (e.g. to keep the empty space in the layout),
      // we want to at least make sure these columns are hidden from screen readers
      {...(isColumnEmpty && { 'aria-hidden': 'true' })}
    >
      {children}
    </div>
  );
}
