import { Skeleton } from '@knack/asterisk-react';

import type { KnackObjectKey } from '@/types/schema/KnackObject';
import { useTableQueries } from '@/hooks/api/queries/useTableQuery';
import { useSupportAccessTool } from '@/hooks/useSupportAccessTool';
import { cn } from '@/utils/tailwind';
import { useSessionContext } from '@/context/SessionContext';

function Banner({ children, className }: { children: React.ReactNode; className?: string }) {
  return <div className={cn('bg-warning p-4', className)}>{children}</div>;
}

export function SupportAccessToolBanner() {
  // This file strings don't need to be translated as it's for internal purposes.
  const session = useSessionContext();
  const queries = useTableQueries({
    tableKeys:
      session?.user.profileKeys.map((key) => key.replace('profile', 'object') as KnackObjectKey) ??
      []
  });
  const { isVisible, isEnabled, isUnavailable, isPending } = useSupportAccessTool();

  if (isPending || !isVisible) {
    return null;
  }

  if (!isEnabled) {
    return (
      <Banner>
        Support access for this app has been disabled. Please contact the account owner to enable
        support access.
      </Banner>
    );
  }

  if (isUnavailable) {
    return (
      <Banner>
        Support access for this app is not available. Please request shared builder access and
        permission to create a test user as needed.
      </Banner>
    );
  }

  if (session) {
    return (
      <Banner className="flex">
        You are accessing this app as user <b className="mx-1">{session.user.email}</b>
        {session.user.profileKeys.length > 0 && ` with user role(s): `}
        {queries.map(({ isLoading, data }, index) => (
          <>
            {isLoading || !data ? (
              <Skeleton className="mx-2 w-24" />
            ) : (
              <b className="ml-1">{data?.name}</b>
            )}
            {index !== queries.length - 1 ? ',' : '.'}
          </>
        ))}
      </Banner>
    );
  }

  return <Banner>You are accessing this app on a Knack CRM URL.</Banner>;
}
