import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiTrash as DeleteIcon, HiPencil as EditIcon } from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import {
  autoUpdate,
  FloatingPortal,
  offset,
  safePolygon,
  useFloating,
  useHover,
  useInteractions
} from '@floating-ui/react';

import {
  type DetailsView,
  type DetailsViewInput,
  type DetailsViewOuterColumn
} from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { DetailsFieldInput } from '@/components/views/details/DetailsFieldInput';
import { DetailsStaticInput } from '@/components/views/details/DetailsStaticInput';
import { useViewContext } from '@/components/views/ViewContext';
import { DragInsertIndicator } from '@/pages/page/page-editor/page-editor-sortable/DragInsertIndicator';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import {
  useSectionEditorContext,
  type ViewInputHoverIdentifier
} from '@/pages/page/page-editor/SectionEditorContext';

interface DetailsInputEditorProps {
  input: DetailsViewInput;
  record: FormattedViewRecord | undefined;
  draggingOverInsertPosition?: 'before' | 'after' | null;
  isLastInGroup?: boolean;
  isDragOverlay?: boolean;
}

export function DetailsInputEditor({
  input,
  record,
  draggingOverInsertPosition,
  isLastInGroup,
  isDragOverlay
}: DetailsInputEditorProps) {
  const [t] = useTranslation();

  const { view } = useViewContext<DetailsView | MapView | ListView>();
  const { updatePage, isDraggingActive, startEditViewInput } = usePageEditorContext();
  const { setViewInputHover, currentHoveredViewInput } = useSectionEditorContext();
  const { listeners, setActivatorNodeRef, isDragging } = useSortable({
    id: input.id
  });

  const viewInputHoverIdentifier: ViewInputHoverIdentifier = `${view.key}:${input.id}`;
  const isInputHovered = currentHoveredViewInput === viewInputHoverIdentifier;
  const isInputActiveState = isInputHovered || isDragOverlay;
  const isInputEditable = input.type !== 'divider';

  const {
    refs,
    floatingStyles,
    elements,
    context: floatingContext
  } = useFloating({
    open: isInputActiveState,
    onOpenChange: (open) => {
      if (open) {
        setViewInputHover(viewInputHoverIdentifier);
      } else {
        setViewInputHover(null);
      }
    },
    placement: 'bottom-start',
    middleware: [
      offset(({ rects }) => ({
        mainAxis: -rects.floating.height,
        crossAxis: -1 // Offseting the horizontal position by 1px to account for the border of the field inputs groups
      }))
    ],
    whileElementsMounted(referenceEl, floatingEl, update) {
      const cleanup = autoUpdate(referenceEl, floatingEl, update, {
        animationFrame: true
      });
      return cleanup;
    }
  });

  const hover = useHover(floatingContext, {
    handleClose: safePolygon({
      blockPointerEvents: true
    }),
    enabled: !isDraggingActive
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  function startEditInput() {
    startEditViewInput({ viewKey: view.key, viewInputId: input.id });
  }

  function onInputDelete(event: React.MouseEvent) {
    event.stopPropagation();

    const getUpdatedOuterColumns = (columns: DetailsViewOuterColumn[]) =>
      columns.map((column) => ({
        ...column,
        groups: [
          {
            ...column.groups[0],
            columns: [column.groups[0].columns[0].filter((i) => i.id !== input.id)]
          }
        ]
      }));

    const updatedView =
      view.type === 'map'
        ? {
            details: {
              ...view.details,
              columns: getUpdatedOuterColumns(view.details.columns)
            }
          }
        : {
            columns: getUpdatedOuterColumns(view.columns)
          };

    updatePage({
      type: 'view',
      origin: 'live-app',
      action: 'update',
      updatedView: {
        ...view,
        ...updatedView
      }
    });
  }

  function onInputClick(event: React.MouseEvent) {
    // We want to ensure that the click event originated from within the input and not from some other element that propagated the event back to the input.
    // For instance, when rendering a child form view in a modal, clicking on the modal backdrop should not trigger the click event on this connection input.
    if (event.target !== event.currentTarget) {
      return;
    }

    event.stopPropagation();
    startEditInput();
  }

  function onInputKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    event.preventDefault();

    if (event.key === 'Enter' || event.key === 'Space') {
      startEditInput();
    }
  }

  // Reset input hover state when component unmounts
  useEffect(
    () => () => {
      setViewInputHover(null);
    },
    [setViewInputHover]
  );

  return (
    <>
      <DragInsertIndicator isVisible={draggingOverInsertPosition === 'before'} />

      <section
        ref={refs.setReference}
        className={cn({
          'opacity-20': isDragging,
          'bg-card': isDragOverlay,
          'pointer-events-none': isDraggingActive
        })}
        {...getReferenceProps()}
      >
        {input.type === 'field' ? (
          <DetailsFieldInput input={input} record={record} isLastInGroup={isLastInGroup} />
        ) : (
          <DetailsStaticInput input={input} record={record} />
        )}

        {isInputActiveState && !isDragging && (
          <FloatingPortal>
            <div
              ref={refs.setFloating}
              className="flex flex-col items-start"
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <div
                role="button"
                tabIndex={0}
                onClick={onInputClick}
                onKeyDown={onInputKeyDown}
                className="relative rounded-lg border border-blue-500 after:absolute after:inset-0 after:bg-blue-500/10"
                style={{
                  width: elements.domReference?.clientWidth
                    ? elements.domReference.clientWidth + 2 // Adding 2px: 1px to account for the border of the field inputs groups, and 1px to account for the offset of the floating element
                    : undefined,
                  height: elements.domReference?.clientHeight
                    ? elements.domReference.clientHeight + 1 // Adding 1px to account for the vertical border of each of the field inputs inside a group
                    : undefined
                }}
              >
                <span className="sr-only">{t('components.page_editor.editable_element')}</span>
              </div>
              <div className="absolute left-0 top-0 rounded-br-lg rounded-tl-lg border border-blue-500 bg-blue-500">
                <div className="flex h-6 items-center px-1 text-sm text-white">
                  <button
                    type="button"
                    aria-label={t('actions.drag')}
                    className="mr-1 inline-flex size-5 cursor-move items-center justify-center rounded-md text-white hover:bg-white/25"
                    ref={setActivatorNodeRef}
                    {...listeners}
                  >
                    <DragIcon size={16} />
                  </button>
                  <button
                    type="button"
                    aria-label={t('actions.edit')}
                    className={cn(
                      'mr-1 inline-flex size-5 items-center justify-center rounded-md text-white hover:bg-white/25',
                      {
                        'cursor-not-allowed': !isInputEditable
                      }
                    )}
                    onClick={startEditInput}
                    disabled={!isInputEditable}
                  >
                    <EditIcon size={14} />
                  </button>
                  <button
                    type="button"
                    aria-label={t('actions.delete')}
                    className="inline-flex size-5 items-center justify-center rounded-md text-white hover:bg-white/25"
                    onClick={onInputDelete}
                  >
                    <DeleteIcon size={14} />
                  </button>
                </div>
              </div>
            </div>
          </FloatingPortal>
        )}
      </section>

      <DragInsertIndicator isVisible={draggingOverInsertPosition === 'after'} />
    </>
  );
}
