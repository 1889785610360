import React from 'react';
import { HiChevronRight as ArrowIcon } from 'react-icons/hi2';
import { Link } from 'react-router-dom';

import { usePageSegmentsHelpers } from '@/hooks/helpers/usePageSegmentsHelpers';
import { cn } from '@/utils/tailwind';
import { usePageContext } from '@/context/PageContext';

export function BuilderAppBreadcrumbs() {
  const { activePage } = usePageContext();
  const { getPageAncestorPages } = usePageSegmentsHelpers();

  if (!activePage) {
    return null;
  }

  const ancestorPages = getPageAncestorPages(activePage);

  // Skip showing breadcrumbs if there are no ancestor pages
  if (ancestorPages.length === 0) {
    return null;
  }

  const linkClasses = 'max-w-40 truncate';

  return (
    <div className="mb-4 flex items-center gap-2">
      {ancestorPages.map((ancestorPage) => (
        <React.Fragment key={ancestorPage.slug}>
          <Link to={ancestorPage.slug} className={cn('hover:text-brand-default', linkClasses)}>
            {ancestorPage.name}
          </Link>
          <ArrowIcon />
        </React.Fragment>
      ))}

      <span className={cn('text-brand-default', linkClasses)}>{activePage.name}</span>
    </div>
  );
}
