import { useRef } from 'react';

import { type ListView } from '@/types/schema/views/ListView';
import {
  formatViewRecordsResponse,
  useViewMultipleRecordsQuery,
  type ViewRecordsResponse
} from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { useContainerQuery } from '@/hooks/useContainerQuery';
import { useViewSearchParams } from '@/hooks/useViewSearchParams';
import { isPageEditor } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { DetailsOuterColumnsSortableWrapper } from '@/components/views/details/details-columns-sortable/DetailsOuterColumnsSortableWrapper';
import { DetailsOuterColumns } from '@/components/views/details/DetailsOuterColumns';
import { ViewFooterSection } from '@/components/views/view-footer/ViewFooterSection';
import { ViewToolbarSection } from '@/components/views/view-options/ViewToolbarSection';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';

export function ListViewRender({ sourceData }: { sourceData?: ViewRecordsResponse }) {
  const { view } = useViewContext<ListView>();
  const { rowsPerPage, currentPage, searchValueFromParams, activeViewFilters } =
    useViewSearchParams(view);

  const { data: listData } = useViewMultipleRecordsQuery({
    view,
    objectKey: view.source.object,
    enabled: !sourceData,
    options: {
      rowsPerPage,
      page: currentPage,
      ...(searchValueFromParams && { search: searchValueFromParams }),
      ...(activeViewFilters && { filters: JSON.stringify(activeViewFilters) })
    }
  });

  const containerRef = useRef(null);
  const isSmallVersion = useContainerQuery(containerRef, { maxWidth: 750 });

  const parsedListData = sourceData ? formatViewRecordsResponse(sourceData) : listData;
  const { list_layout: listColumnLayout } = view;
  const totalPageNumber = listData?.totalPages || 0;

  const listColumnLayoutNumber: Record<string, number> = {
    'one-column': 1,
    'two-column': 2,
    'three-column': 3
  };

  return (
    <div ref={containerRef} className="w-full">
      <ViewHeaderSection view={view} className="mb-4" />
      <ViewToolbarSection className="mb-4" />
      <div
        className={cn('mb-4 grid w-full grid-cols-1 gap-4', {
          'md:grid-cols-2': listColumnLayoutNumber[listColumnLayout] === 2,
          'md:grid-cols-3': listColumnLayoutNumber[listColumnLayout] === 3
        })}
      >
        {parsedListData?.records.length ? (
          parsedListData.records.map((record, recordIndex) =>
            isPageEditor() ? (
              <DetailsOuterColumnsSortableWrapper
                key={record.values.id}
                view={view}
                record={record}
                recordIndex={recordIndex}
                withNonSortableOverlay
              />
            ) : (
              <DetailsOuterColumns key={record.values.id} record={record} />
            )
          )
        ) : (
          <p>{view.no_data_text}</p>
        )}
      </div>
      <ViewFooterSection totalPageNumber={totalPageNumber} isSmallFooter={isSmallVersion} />
    </div>
  );
}
