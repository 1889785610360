import { type DetailsView } from '@/types/schema/views/DetailsView';
import { useViewMultipleRecordsQuery } from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import {
  useViewRecordQuery,
  type FormattedViewRecord
} from '@/hooks/api/queries/useViewRecordQuery';
import { isPageEditor } from '@/utils/iframe';
import { DetailsOuterColumns } from '@/components/views/details/DetailsOuterColumns';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { usePageContext } from '@/context/PageContext';
import { DetailsOuterColumnsSortableWrapper } from './details-columns-sortable/DetailsOuterColumnsSortableWrapper';

export function DetailsViewRender({ sourceData }: { sourceData?: FormattedViewRecord }) {
  const { activePageRecordId } = usePageContext();
  const { view } = useViewContext<DetailsView>();

  // If there is a record associated with the page, we use that record id to fetch the data
  const { data: viewRecordFromPage } = useViewRecordQuery({
    view,
    enabled: !sourceData && !!activePageRecordId
  });

  // If there is no record associated with the page, we use the record id associated with the view to fetch the data
  const { data: viewRecordFromView } = useViewMultipleRecordsQuery({
    view,
    objectKey: view.source.object,
    enabled: !sourceData && !activePageRecordId
  });

  const detailsRecord = sourceData || viewRecordFromPage || viewRecordFromView?.records[0];

  return (
    <div className="w-full">
      <ViewHeaderSection view={view} className="mb-6" />
      {isPageEditor() ? (
        <DetailsOuterColumnsSortableWrapper view={view} record={detailsRecord} />
      ) : (
        <DetailsOuterColumns record={detailsRecord} />
      )}
    </div>
  );
}
