import { useFormContext } from 'react-hook-form';
import { Input } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { type ViewFilters } from '@/hooks/useViewFilters';
import { isDateTimeRangeOperator } from '@/utils/field-operators';
import { FilterAddressRangeInput } from './FilterAddressRangeInput';
import { FilterBooleanInput } from './FilterBooleanInput';
import { FilterConnectionInput } from './FilterConnectionInput';
import { FilterDateTimeInput } from './FilterDateTimeInput';
import { FilterDateTimeRangeInput } from './FilterDateTimeRangeInput';
import { FilterMultipleChoiceInput } from './FilterMultipleChoiceInput';

interface ViewFilterInputProps {
  field: KnackField;
  criteriaOperator: KnackOperator;
  criteriaIndex: number;
}

export function ViewFilterInput({ field, criteriaOperator, criteriaIndex }: ViewFilterInputProps) {
  const { register } = useFormContext<ViewFilters>();
  const { canFieldStoreDateValues } = useFieldHelpers();

  if (canFieldStoreDateValues(field)) {
    if (isDateTimeRangeOperator(criteriaOperator)) {
      return (
        <FilterDateTimeRangeInput
          criteriaIndex={criteriaIndex}
          criteriaOperator={criteriaOperator}
        />
      );
    }
    return <FilterDateTimeInput field={field} criteriaIndex={criteriaIndex} />;
  }

  if (field.type === 'address' && criteriaOperator === 'near') {
    return <FilterAddressRangeInput criteriaIndex={criteriaIndex} />;
  }

  if (field.type === 'connection') {
    return <FilterConnectionInput field={field} criteriaIndex={criteriaIndex} />;
  }

  if (field.type === 'multiple_choice') {
    return <FilterMultipleChoiceInput field={field} criteriaIndex={criteriaIndex} />;
  }

  if (field.type === 'boolean') {
    return <FilterBooleanInput field={field} criteriaIndex={criteriaIndex} />;
  }

  return (
    <Input
      data-testid={`filter-text-input-${criteriaIndex}`}
      className="h-9"
      {...register(`rules.${criteriaIndex}.value`)}
    />
  );
}
