import { useTranslation } from 'react-i18next';

import { type KnackCriteria, type KnackCriteriaDateTimeValue } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { type TableView } from '@/types/schema/views/TableView';
import { useConnectionRecordsQuery } from '@/hooks/api/queries/useConnectionRecordsQuery';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { getCriteriaTimeString } from '@/utils/date-time-criteria-helpers';
import { getFormattedBooleanDefaultValue } from './views/form/inputs/boolean/helper';
import { useViewContext } from './views/ViewContext';

interface InlineKnackRecordValueProps {
  viewKey: LiveAppViewKey;
  fieldKey: KnackFieldKey;
  value: KnackCriteria['value'];
}

export function InlineKnackValue({ viewKey, fieldKey, value }: InlineKnackRecordValueProps) {
  const [t] = useTranslation();

  const { sourceTable } = useViewContext<TableView>();
  const { canFieldStoreDateValues } = useFieldHelpers();

  const field = sourceTable.fields.find((sourceTableField) => sourceTableField.key === fieldKey);

  const { data: connectionRecords, isLoading } = useConnectionRecordsQuery({
    viewKey,
    fieldKey,
    enabled: field?.type === 'connection'
  });

  if (!field) return null;

  if (isLoading) {
    return t('keywords.loading');
  }

  const connectionRecord =
    field.type === 'connection' &&
    Array.isArray(value) &&
    connectionRecords?.records.find((record) => record.id === value[0]);

  const valueToRender = connectionRecord ? connectionRecord.identifier : value;

  if (field.type === 'boolean' && typeof valueToRender === 'boolean') {
    return getFormattedBooleanDefaultValue(valueToRender, field.format);
  }

  if (canFieldStoreDateValues(field)) {
    const dateTimeValue = value as KnackCriteriaDateTimeValue;

    if (dateTimeValue.date && dateTimeValue.time) {
      return `${dateTimeValue.date} ${getCriteriaTimeString(dateTimeValue, field.format)}`;
    }

    return dateTimeValue.date || getCriteriaTimeString(dateTimeValue, field.format) || '';
  }

  if (!valueToRender) return null;

  return valueToRender as string;
}
