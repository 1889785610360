export function getApplicationBasePathSegments() {
  const pathSegments = window.location.pathname.split('/').filter(Boolean);
  const [accountSlug, appSlug] = pathSegments;
  return { accountSlug, appSlug };
}

export function getApplicationBasePath() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  if (!accountSlug || !appSlug) {
    return '/';
  }

  return `/${accountSlug}/${appSlug}`;
}

export function getOldLiveAppBaseUrl() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();
  return `//${accountSlug}.${import.meta.env.PUBLIC_OLD_LIVE_APP_HOST}/${appSlug}`;
}
