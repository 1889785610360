import { Controller, useFormContext } from 'react-hook-form';
import { RichTextEditor } from '@knack/asterisk-react';
import DOMPurify from 'dompurify';

import { type FormViewRichTextInput } from '@/types/schema/views/form/RichText';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useThemingContext } from '@/context/ThemingContext';

export function RichTextInput({
  input,
  isReadOnly
}: {
  input: FormViewRichTextInput;
  isReadOnly?: boolean;
}) {
  const { getValues } = useFormContext();
  const { theme } = useThemingContext();

  const inputValue = getValues(input.field.key);

  const sanitizedValue = DOMPurify.sanitize(inputValue);

  if (input.read_only) {
    return (
      <div
        className={cn('border border-default bg-muted p-2', {
          'rounded-lg': theme.appearance.corners === 'rounded'
        })}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: sanitizedValue }}
      />
    );
  }

  return (
    <Controller
      name={input.field.key}
      disabled={isReadOnly}
      render={({ field, formState: { errors } }) => (
        <>
          <RichTextEditor
            data-testid="form-view-rich-text-input"
            content={inputValue}
            intent={errors[input.field.key] ? 'destructive' : 'default'}
            className="min-w-[200px]"
            onUpdate={({ editor }) => {
              field.onChange(editor.isEmpty ? '' : editor.getHTML());
            }}
          />
          <FormErrorMessage errors={errors} name={input.field.key} />
        </>
      )}
    />
  );
}
